<template>
  <div class="zjly-top-header">
    <div class="logo" @click="goToHome"></div>
    <div class="nav-bar">
      <!-- <div
        class="nav-item"
        :class="{ on: selectedMenuId == '1' }"
        @click="goToHome"
      >
        <div class="nav-item-name">首页</div>
      </div> -->
      <div
        class="nav-item"
        v-for="(item, index) in menuData"
        :key="index"
        :class="{ on: selectedMenuId == item.MenuId }"
        @click="openMenu(item)"
      >
        <!-- <el-dropdown v-if="item.MenuItemList && item.MenuItemList.length">
          <span class="el-dropdown-link">
            {{ item.MenuName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(subMenu, i) in item.MenuItemList"
              :key="i"
              >{{ subMenu.MenuName }}</el-dropdown-item
            >
          </el-dropdown-menu> 
        </el-dropdown>-->
        <div class="nav-item-name">{{ item.MenuName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import defaults from "../../defaults/defaults";
export default {
  name: "TopHeader",
  props: {
    menuData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // menuData: [],
      selectedMenuId: "",
    };
  },
  created() {
    // this.getMenu();
  },
  mounted() {
    this.$nextTick(function () {
      this.getPath();
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "getPath",
  },
  methods: {
    getPath() {
      const routeName = this.$route.name;
      // console.log(routeName, "路由name");
      this.selectedMenuId = defaults.menuIds[routeName];
    },
    goToHome() {
      this.$router.push({ path: "/" });
    },
    /**
     * 菜单跳转
     * @param {*} item
     */
    openMenu(item) {
      let path = item.path;
      this.$router.push({ path: path });
      this.$emit("select", item);
    },
    /**
     *获取菜单
     */
    getMenu() {
      let params = {
        QueryParam: {
          Status: 1,
        },
      };
      api.getMenuList(params).then((res) => {
        this.originalMenu = res.data.Results || {};
        let menus = [];
        this.originalMenu.forEach((item) => {
          // let url = defaults.menuMap[item.Id];
          let path = item.Attributes.Url;
          let children = item.Children || [];
          children.forEach((child) => {
            child.MenuId = child.Id;
            child.MenuName = child.Label;
            child.path = path;
          });
          let menuItem = {
            MenuId: item.Id,
            path: path,
            MenuName: item.Label,
            MenuItemList: children,
            type: 0,
          };
          menus.push(menuItem);
        });
        this.menuData = menus;
        this.$emit("setHomeBanner", this.menuData[0]);
      });
    },
  },
};
</script>

<style lang="scss">
.zjly-top-header {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 76px;
  align-items: center;
  .logo {
    width: 188px;
    height: 52px;
    background: url(../../assets/img/img_logo@2x.png) no-repeat center;
    cursor: pointer;
    background-size: 188px 52px;
  }
  .nav-bar {
    display: flex;
    .nav-item {
      margin-left: 44px;
      display: flex;
      position: relative;
      cursor: pointer;
      .nav-item-name {
        color: #181818;
        font-size: 16px;
      }
      &:hover,
      &.on {
        .nav-item-name {
          color: #b60005;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
