<template>
  <div>投诉建议</div>
</template>

<script>
export default {
  name: "ServerSuggest",
};
</script>

<style></style>
