<template>
  <div class="zjly-web-about">
    <banner-nav :datas="bannerList" :height="300"></banner-nav>
    <page-nav-bar
      :datas="menubarList"
      @select="navBarClick"
      :defaultId="defaultId"
    ></page-nav-bar>
    <div class="zjly-web-server-content">
      <!-- <router-view></router-view> -->
      <div class="zjly-web-server-nr w1200">
        <h3 class="zjly-web-server-title bgicon-fwbz" id="fwbz">服务保障</h3>
        <div class="zjly-web-server-html" v-html="fwbzObj.Content"></div>
        <h3 class="zjly-web-server-title bgicon-fwwd" id="fwwd">服务网点</h3>
        <div class="zjly-web-server-html" v-html="fwwdObj.Content"></div>
        <h3 class="zjly-web-server-title bgicon-tsjy" id="tsjy">投诉与建议</h3>
        <div class="tsjy-main">
          <div class="tsjy-left">
            <p>亲爱的用户，非常感谢您选择立元科技。</p>
            <p>
              您对我们有任何意见和建议，都可以在此留言，我们将尽快给您解答，或者您还可以拨打投诉热线！0571-88858511
            </p>
            <p>客服人员会及时帮您解答，感谢您的理解和支持！</p>
            <p>请准确、无误地填写您的姓名、联系方法，方便我们与您联系。</p>
          </div>
          <div class="tsjy-right">
            <div class="tsjy-item mr40">
              <p>姓名/NAME：</p>
              <div>
                <el-input width="355" v-model.trim="params.Name"></el-input>
              </div>
            </div>
            <div class="tsjy-item">
              <p>电话/TEL：</p>
              <div>
                <el-input :width="355" v-model.trim="params.Phone"></el-input>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="tsjy-item flex2">
              <p>需求描述/SOR：</p>
              <div class="zjliyuan-scrollbar">
                <el-input
                  v-model.trim="params.Description"
                  type="textarea"
                  resize="none"
                ></el-input>
              </div>
            </div>

            <div class="tsjy-btn" @click="submit">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerNav from "../../component/banner/banner-nav.vue";
import proMixins from "../../mixins/proMixins";
import PageNavBar from "../../component/pagenavbar/page-nav-bar.vue";
import defaults from "../../defaults/defaults";
import validUtil from "../../utils/valid";
import api from "../../api/api";
export default {
  name: "Server",
  components: {
    bannerNav,
    PageNavBar,
  },
  mixins: [proMixins],
  data() {
    return {
      menubarList: [
        {
          Id: "fwbz",
          Label: "服务保障",
        },
        {
          Id: "fwwd",
          Label: "服务网点",
        },
        {
          Id: "tsjy",
          Label: "投诉与建议",
        },
      ],

      params: {
        Name: "",
        Phone: "",
        Description: "",
      },
      defaultId: "",
      fwbzObj: "",
      fwwdObj: "",
    };
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.defaultId = val;
          let id = defaults.hrefIds[val];
          this.$nextTick(() => {
            this.scrollToElement(id);
          });
        }
      },
    },
  },
  created() {
    this.getServerDetail();
  },
  mounted() {
    let serverId = localStorage.getItem("serverId");
    this.defaultId = serverId;
    localStorage.removeItem("serverId");
  },
  methods: {
    /**
     * 服务支持文章列表
     */
    getServerDetail() {
      let id = defaults.menuIds.fwbz;
      let params = {
        QueryParam: {
          Id: id,
        },
      };
      api.getArticleDetail(params).then((res) => {
        this.fwbzObj = res.data.Results || {};
        let extContent = this.fwbzObj.ArticleExt[0];
        this.fwwdObj = extContent;
        if (this.defaultId) {
          this.$nextTick(() => {
            let id = this.defaultId;
            this.scrollToElement(id);
          });
        }
      });
    },

    navBarClick(item) {
      this.scrollToElement(item.Id);
    },
    submit() {
      if (!validUtil.empty(this.params.Name)) {
        this._message.warning("请填写姓名/NAME！");
        return;
      }
      if (!validUtil.telPhone(this.params.Phone)) {
        this._message.warning("请填写规范的手机号码！");
        return;
      }
      if (!validUtil.empty(this.params.Description)) {
        this._message.warning("请填写需求描述/SOR！");
        return;
      }
      this._message.success("提交成功！");
    },
    // 在Vue组件的methods中添加
    scrollToElement(id) {
      // 假设你要滚动到的元素的ID是'target-element'
      const el = document.getElementById(id);
      if (el) {
        // 使用window.scrollTo实现平滑滚动
        let div = document.querySelector("body");
        if (this.defaultId) {
          setTimeout(() => {
            div.scrollTo({
              top: el.offsetTop - 112,
              behavior: "smooth",
            });
          }, 1000);
        } else {
          div.scrollTo({
            top: el.offsetTop - 112,
            behavior: "smooth",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.zjly-web-server-content {
  padding-bottom: 80px;
  .zjly-web-server-title {
    font-size: 28px;
    color: #181818;
    line-height: 37px;
    background: url(../../assets/img/icon_fwbz.png) no-repeat left center;
    padding-left: 35px;
    margin-bottom: 36px;
    &.bgicon-fwbz {
      background: url(../../assets/img/icon_fwbz.png) no-repeat left center;
    }
    &.bgicon-fwwd {
      background: url(../../assets/img/icon_fwwd.png) no-repeat left center;
    }
    &.bgicon-tsjy {
      background: url(../../assets/img/icon_tsjy.png) no-repeat left center;
    }
  }
  .zjly-web-server-html {
    padding-bottom: 80px;
    font-size: 16px;
    color: #6e6d72;
    line-height: 32px;
    img {
      max-width: 100%;
    }
  }
  .tsjy-main {
    display: flex;
    height: 432px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.08);

    .tsjy-left {
      width: 350px;
      height: 100%;
      background: #b60005;
      box-shadow: 0px 0px 32px 0px rgba(182, 0, 5, 0.08);
      flex: 0 auto;
      padding: 34px 39px;
      box-sizing: border-box;
      p {
        font-size: 18px;
        color: #ffffff;
        line-height: 28px;
        text-align: justify;
        padding-bottom: 25px;
      }
    }
    .tsjy-right {
      padding: 44px 50px 0 50px;
      flex: 1;
      .tsjy-item {
        width: 355px;
        float: left;
        padding-bottom: 40px;
        p {
          font-size: 16px;
          color: #6e6d72;
          height: 21px;
          line-height: 21px;
          margin-bottom: 10px;
        }
        &.mr40 {
          margin-right: 40px;
        }
        &.flex2 {
          width: 750px;
          height: 86px;
          float: none;
          .el-textarea__inner {
            height: 86px;
          }
        }
      }
      .tsjy-btn {
        width: 750px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        font-size: 18px;
        color: #ffffff;
        background: #b60005;
        box-shadow: 0px 8px 16px 0px rgba(182, 0, 5, 0.31);
        margin-top: 40px;
        cursor: pointer;
        user-select: none;
        &:hover {
          background: #d6030a;
          box-shadow: 0px 8px 32px 0px rgba(182, 0, 5, 0.31);
        }
      }
    }
  }
}
</style>
