<template>
  <div class="zjly-web-article-detail">
    <!-- <div class="zjly-web-news-detail-breadcrumb w1200"> -->
    <breadcrumb :datas="linksList"></breadcrumb>
    <!-- </div> -->
    <div class="zjly-web-article-detail-content">
      <div class="zjly-web-article-detail-top w1200">
        <div class="zjly-web-article-detail-item-nr">
          <h2>{{ articleDetail.Title }}</h2>
          <div
            v-html="articleDetail.Content"
            class="zjly-web-article-detail-item-html clearfix"
          ></div>
        </div>
      </div>
      <div
        class="zjly-web-article-detail-item"
        v-for="(item, index) in articleDetail.ArticleExt"
        :class="{ 'item-gray': index % 2 == 0 }"
        :key="index"
      >
        <div class="zjly-web-article-detail-item-nr w1200">
          <div class="zjly-web-article-detail-title">
            <h2>{{ item.Title }}</h2>
          </div>
          <div
            class="zjly-web-article-detail-item-html clearfix"
            v-html="item.Content"
          ></div>
        </div>
      </div>
    </div>
    <!-- <div class="zjly-web-news-detail-nr w1200">
      <h2 class="zjly-web-news-detail-h2">{{ articleDetail.Title }}</h2>
      <div class="zjly-web-news-detail-time">
        {{ articleDetail.PublishTime }}
      </div>
      <div
        class="zjly-web-news-detail-html"
        v-html="articleDetail.Content"
      ></div>
    </div> -->
  </div>
</template>

<script>
import proMixins from "../../mixins/proMixins";
import Breadcrumb from "../../component/pagenavbar/breadcrumb.vue";
export default {
  name: "CaseDetail",
  components: {
    Breadcrumb,
  },
  mixins: [proMixins],
  data() {
    return {
      linksList: [],
      articleDetail: {
        Title: "",
        Content: "",
      },
    };
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(val) {
        let id = val;
        this.getArticleDetail(id);
      },
    },
    articleDetail: {
      deep: true,
      handler(val) {
        if (val && val.Title) {
          // 设置面包屑导航数组
          let links = [];
          let lastNav = {
            MenuId: val.Id,
            Label: val.Title,
            type: 0,
          };
          links.push(lastNav);
          this.linksList = links;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.casedetail-page {
  padding-bottom: 80px;
}
@import "../../assets/css/web.scss";
</style>
