<template>
  <div class="zjly-web-home-case">
    <div
      v-for="(item, index) in picList"
      :key="index"
      :class="'zjly-web-home-case-' + (index + 1)"
      @click="viewDetail(item)"
    >
      <div class="title">{{ item.Title }}</div>
      <el-image :src="item.PicUrl">
        <div slot="error" class="image-error-slot"></div>
      </el-image>
    </div>
    <!-- <div class="zjly-web-home-case-2">
      <el-image src="">
        <div slot="error" class="image-error-slot"></div
      ></el-image>
    </div>
    <div class="zjly-web-home-case-3">
      <el-image src="">
        <div slot="error" class="image-error-slot"></div
      ></el-image>
    </div>
    <div class="zjly-web-home-case-4">
      <el-image src="">
        <div slot="error" class="image-error-slot"></div
      ></el-image>
    </div>
    <div class="zjly-web-home-case-5">
      <el-image src="">
        <div slot="error" class="image-error-slot"></div
      ></el-image>
    </div>
    <div class="zjly-web-home-case-6">
      <el-image src="">
        <div slot="error" class="image-error-slot"></div
      ></el-image>
    </div> -->
  </div>
</template>

<script>
import defaults from "../../defaults/defaults";
export default {
  name: "PicturePuzzle",
  inject: ["getAllMenu"],
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    datas: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.picList = val.slice();
        }
      },
    },
  },
  computed: {
    allMenu() {
      return this.getAllMenu();
    },
  },
  data() {
    return {
      picList: [],
    };
  },
  methods: {
    viewDetail(item) {
      // 设置面包屑导航数据
      let links = [];
      let caseMenu = this.allMenu.find(
        (item) => item.MenuId == defaults.menuIds["solution"]
      );
      let second = Object.assign({ type: "2" }, caseMenu);
      links.push(second);
      links.push({
        MenuId: item.Id,
        Label: item.Title,
        type: 0,
      });
      // 跳转
      this.$router.push({
        path: "/solutiondetail/" + item.ArticleId,
      });
    },
  },
};
</script>

<style lang="scss">
.zjly-web-home-case {
  position: relative;
  height: 540px;
  margin-bottom: 40px;
  cursor: pointer;

  .title {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 2;
    font-size: 20px;
    color: #ffffff;
    line-height: 26px;
    font-style: normal;
    pointer-events: none;
  }
  .el-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    cursor: pointer;

    img {
      transform: scale(1.1);
      transition: all 0.3s;
      &:hover {
        transform: scale(1);
      }
    }
    .image-error-slot {
      width: 100%;
      height: 100%;
      background: #fff;
    }
  }
  .zjly-web-home-case-1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 300px;
    height: 540px;
  }
  .zjly-web-home-case-2 {
    position: absolute;
    left: 315px;
    top: 0;
    width: 570px;
    height: 325px;
  }
  .zjly-web-home-case-3 {
    position: absolute;
    left: 315px;
    top: 340px;
    width: 277px;
    height: 200px;
  }
  .zjly-web-home-case-4 {
    position: absolute;
    left: 608px;
    top: 340px;
    width: 277px;
    height: 200px;
  }
  .zjly-web-home-case-5 {
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    height: 160px;
  }
  .zjly-web-home-case-6 {
    position: absolute;
    right: 0;
    top: 175px;
    width: 300px;
    height: 365px;
  }
}
</style>
