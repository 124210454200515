<template>
  <div class="zjly-web-about">
    <banner-nav :datas="bannerList" :height="300"></banner-nav>
    <page-nav-bar
      :datas="menuList"
      @select="navBarClick"
      :defaultId="defaultId"
    ></page-nav-bar>
    <div class="zjly-web-about-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import bannerNav from "../../component/banner/banner-nav.vue";
import proMixins from "../../mixins/proMixins";
import PageNavBar from "../../component/pagenavbar/page-nav-bar.vue";
import defaults from "../../defaults/defaults";
export default {
  name: "About",
  components: {
    bannerNav,
    PageNavBar,
  },
  mixins: [proMixins],
  data() {
    return {
      menuList: [],
      selectedSecondMenuItem: {},
      selectedThirdMenuItem: {},
      mapIds: {},
      defaultId: "",
    };
  },
  mounted() {
    let aboutId = localStorage.getItem("aboutId");
    if (aboutId) {
      this.defaultId = aboutId;
      localStorage.removeItem("aboutId");
    }
  },
  methods: {
    navBarClick(item) {
      if (item) {
        this.$router.push({
          path: defaults.menuMap[item.Id],
        });
      }
    },
  },
};
</script>

<style></style>
