<template>
  <div>
    <swiper
      class="swiper gallery-thumbs home-banner white-theme"
      :options="swiperOptionThumbs"
      ref="swiperThumbs"
      @ready="handleSwiperReadied"
      :auto-update="true"
      :auto-destroy="true"
      :delete-instance-on-destroy="true"
      :cleanup-styles-on-destroy="true"
    >
      <swiper-slide
        class="slide-banner"
        v-for="(item, index) in datas"
        :key="index"
      >
        <div
          class="slide-banner-img"
          :style="{ 'background-image': 'url(' + item.background + ')' }"
        >
          <div class="w1200">
            <img :src="item.text" />
            <a
              class="home-banner-btn-more"
              v-if="item.article"
              @click="goToProDetail(item)"
              >了解详情</a
            >
          </div>
          <!-- <img src="../../assets/img/banner_yyzj@2x.png" /> -->
          <!-- <img :src="item" /> -->
        </div>
      </swiper-slide>
      <div
        class="swiper-pagination"
        slot="pagination"
        v-show="datas.length > 1"
      ></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "Banner",
  inject: ["getAllMenu"],
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptionThumbs: {
        //小手掌抓取滑动
        loop: false,
        autoplay: true, //可选选项，自动滑动
        // initialSlide:this.currentSceneIndex,
        grabCursor: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true, //防止不可点击
        // slideToClickedSlide: true,
        slidesPerView: 1,
        observer: true,
        autoScrollOffset: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".page-btn-next",
          prevEl: ".page-btn-prev",
        },
        lazy: {
          loadPrevNext: true,
        },
        isLocated: false,
        effect: "fade",
      },
    };
  },
  computed: {
    allMenu() {
      return this.getAllMenu();
    },
  },
  methods: {
    goToProDetail(item) {
      let article = item.article;
      this.$router.push({
        path: "productdetail/" + article.Id,
      });
    },
    prevPage() {},
    nextPage() {},
    handleSwiperReadied() {
      this.$refs.swiperThumbs.$swiper.update();
    },
    /**
     * 根据MenuId查找菜单
     * @param {*} menuArray 菜单
     * @param {*} id 菜单id
     */
    getMenuById(menuArray, id) {
      if (menuArray == null || menuArray.length == 0) {
        return null;
      }
      for (let i = 0; i < menuArray.length; i++) {
        let menu = menuArray[i];
        if (menu.MenuId == id) {
          return menu;
        }
        let findResult = this.getMenuById(menu.MenuItemList, id);
        if (findResult) {
          return findResult;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.home-banner {
  .swiper-pagination {
    bottom: 20px;
  }
  &.swiper-container-horizontal
    .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin-right: 37px !important;
  }
}
.slide-banner-img {
  height: 650px;
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .home-banner-btn-more {
    display: block;
    width: 135px;
    text-align: center;
    line-height: 46px;
    height: 46px;
    border-radius: 23px;
    border: 1px solid #6e6d72;
    transition: 0.4s;
    cursor: pointer;
    margin-left: 26px;
    margin-top: 50px;
    opacity: 0.7;
    &:hover {
      border: 1px solid #c7000b;
      background-color: #c7000b;
      color: #fff;
      opacity: 1;
    }
  }
}
.white-theme {
  .slide-banner-img {
    .home-banner-btn-more {
      border: 1px solid #fff;
      color: #fff;
      &:hover {
        border: 1px solid #c7000b;
      }
    }
  }
}
</style>
