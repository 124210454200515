<template>
  <div>服务网点</div>
</template>

<script>
export default {
  name: "ServerNet",
};
</script>

<style></style>
