import defaults from "../defaults/defaults";
import api from "../api/api";
export default {
  inject: ["getCurrentMenu", "getAllMenu"],
  data() {
    return {
      bannerList: [],
      articleDetail: {
        Title: "",
        Content: "",
      },
    };
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   handler() {
    //     this.getBanner();
    //     this.getPath();
    //   },
    // },
    currentMenu: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) {
          return;
        }
        this.menuList = val.MenuItemList;
        this.bannerList = val.Attributes.BannerList || [];
        this.afterGetCurrentMenu && this.afterGetCurrentMenu();
      },
    },
  },
  computed: {
    currentMenu() {
      return this.getCurrentMenu();
    },
    allMenu() {
      return this.getAllMenu();
    },
  },
  methods: {
    /**
     * 根据路由获取子菜单
     */
    getPath() {
      const headerOn = this.$route.name;
      let menuId = defaults.menuIds[headerOn];
      this.getChildMenu(menuId);
    },
    /**
     * 根据路由获取菜单banner图
     */
    getBanner() {
      const headerOn = this.$route.name;
      let menuId = this.menuIds[headerOn];
      let params = {
        QueryParam: {
          Id: menuId,
        },
      };
      api.getMenuDetail(params).then((res) => {
        let results = res.data.Results || {};
        this.bannerList = results.MenuExt ? results.MenuExt.PicUrlList : [];
      });
    },
    /**
     * 获取菜单子菜单
     * @param {*} menuId
     */
    getChildMenu(menuId) {
      let params = {
        QueryParam: {
          Status: 1,
          Parent: menuId,
        },
      };
      api.getMenuList(params).then((res) => {
        let menus = res.data.Results || [];
        // menus.forEach((item) => {
        //   item.path = "/" + item.Attributes.Url;
        // });
        this.menuList = menus;
      });
    },
    /**
     * 面包屑导航数组
     * @returns
     */
    getLinks() {
      let links = [];
      if (this.currentMenu) {
        links.push(this.currentMenu);
      }
      if (this.selectedSecondMenuItem) {
        let second = Object.assign({ type: "2" }, this.selectedSecondMenuItem);
        links.push(second);
      }
      if (this.selectedThirdMenuItem && this.selectedThirdMenuItem.Id) {
        let third = Object.assign({ type: "3" }, this.selectedThirdMenuItem);
        links.push(third);
      }
      return links;
    },
    /**
     * 获取文章列表
     */
    getArticleList(id) {
      let params = {
        QueryParam: {
          MenuId: id,
          Status: 1, //后面换成1
        },
      };
      api.getArticleList(params).then((res) => {
        let data = res.data.Results || [];
        this.ArticleList = data;
      });
    },
    /**
     * 获取文章详情
     */
    getArticleDetail(wzid) {
      let id = this.$route.params.id;
      if (wzid) {
        id = wzid;
      }

      if (!id) {
        let articleId = localStorage.getItem("articleId");
        if (articleId) {
          id = articleId;
        }
      } else {
        localStorage.setItem("articleId", id);
      }

      let params = {
        QueryParam: {
          Id: id,
        },
      };
      api.getArticleDetail(params).then((res) => {
        this.articleDetail = res.data.Results || {};
        this.getBreadCrumbLsit(this.articleDetail);
        document.body.scrollTo({
          top: 0,
        });
      });
    },
    /**
     * 根据MenuId查找菜单
     * @param {*} menuArray 菜单
     * @param {*} id 菜单id
     */
    getMenuById(menuArray, id) {
      if (menuArray == null || menuArray.length == 0) {
        return null;
      }
      for (let i = 0; i < menuArray.length; i++) {
        let menu = menuArray[i];
        if (menu.MenuId == id) {
          return menu;
        }
        let findResult = this.getMenuById(menu.MenuItemList, id);
        if (findResult) {
          return findResult;
        }
      }
      return null;
    },
    getMenusByPath(fullPath, allMenu, article) {
      if (fullPath.length === 0) return; // 如果路径为空，直接返回
      let links = [];
      // 遍历fullPath中的每个ID
      for (let i = 0; i < fullPath.length; i++) {
        const menu = this.getMenuById(allMenu, fullPath[i]);
        if (menu) {
          // 确保找到了菜单项
          const link =
            i === 0 ? menu : Object.assign({ type: (i + 1).toString() }, menu);
          links.push(link);
        }
      }
      links.push({
        MenuId: article.Id,
        Label: article.Title,
        type: 0,
      });
      return links;
    },
    // 获取面包屑导航
    getBreadCrumbLsit(article) {
      let fullPath = article.FullPath ? article.FullPath.split("$") : [];
      if (fullPath.length === 0) return; // 如果路径为空，直接返回
      if (fullPath.length > 0) {
        this.linksList = this.getMenusByPath(fullPath, this.allMenu, article);
      }
    },
  },
};
