<template>
  <div class="zjly-web-article-detail">
    <banner-nav :datas="bannerList" :height="300"></banner-nav>
    <div class="zjly-web-nomal-nav-bar">
      <div class="w1200"><breadcrumb :datas="linksList"></breadcrumb></div>
    </div>
    <div class="zjly-web-article-detail-content bg-white">
      <div class="zjly-web-article-detail-top w1200">
        <div class="zjly-web-article-detail-item-nr">
          <h2>{{ articleDetail.Title }}</h2>
          <div
            class="zjly-web-article-detail-item-html clearfix"
            v-html="articleDetail.Content"
          ></div>
        </div>
      </div>

      <div
        class="zjly-web-article-detail-item"
        v-for="(item, index) in articleDetail.ArticleExt"
        :class="{ 'item-gray': index % 2 == 0 }"
        :key="index"
      >
        <div class="zjly-web-article-detail-item-nr w1200">
          <div class="zjly-web-article-detail-title">
            <h2>{{ item.Title }}</h2>
          </div>
          <div
            v-html="item.Content"
            class="zjly-web-article-detail-item-html clearfix"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerNav from "../../component/banner/banner-nav.vue";
import proMixins from "../../mixins/proMixins";
import Breadcrumb from "../../component/pagenavbar/breadcrumb.vue";
export default {
  name: "Solution",
  components: {
    bannerNav,
    Breadcrumb,
  },
  mixins: [proMixins],
  data() {
    return {
      menuList: [],
      selectedSecondMenuItem: null,
      selectedThirdMenuItem: null,
      linksList: [],
    };
  },
  mounted() {
    // let links = this.$route.params.links;
    // if (!links) {
    //   let sesstionLinks = localStorage.getItem("solutionDetailLinks");
    //   if (sesstionLinks) {
    //     links = JSON.parse(sesstionLinks);
    //   }
    // } else {
    //   localStorage.setItem("solutionDetailLinks", JSON.stringify(links));
    // }
    // this.linksList = links;
  },
  methods: {
    afterGetCurrentMenu() {
      this.getArticleDetail();
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/css/web.scss";
</style>
