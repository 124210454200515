<template>
  <div class="zjly-web-breadcrumb">
    <div class="w1200">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <template v-for="(item, index) in datas">
          <el-breadcrumb-item
            :key="index"
            v-if="index != datas.length - 1"
            :to="getPath(item)"
            :replace="true"
            ><span @click="changePage(item)">{{
              item.Label
            }}</span></el-breadcrumb-item
          >
          <el-breadcrumb-item :key="'last' + index" v-else>
            {{ item.Label }}</el-breadcrumb-item
          >
        </template>
        <!-- <el-breadcrumb-item
          v-for="(item, index) in datas"
          :key="index"
          :to="getPath(item)"
          ><span @click="changePage(item)">{{
            item.Label
          }}</span></el-breadcrumb-item
        >-->
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import defaults from "../../defaults/defaults";
export default {
  name: "BreadCrumb",
  inject: ["updateKey"],
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    changePage(item) {
      console.log("item: ", item);
    },
    getPath(item) {
      let path;
      if (item.type == 3) {
        let parent = item.Attributes.Parent;
        let id = item.Id;
        path = {
          name: item.Attributes.Url.substring(1),
          params: { productType: { second: parent, third: id } },
        };
      } else if (item.type == 2) {
        let id = item.Id;
        if (item.Id == defaults.menuIds.news) {
          path = {
            path: item.Attributes.Url,
          };
        } else {
          path = {
            name: item.Attributes.Url.substring(1),
            params: { productType: { second: id } },
          };
        }
      } else {
        path = { path: item.path };
      }
      console.log("path: ", path);

      return path;
    },
  },
};
</script>

<style lang="scss">
.zjly-web-breadcrumb {
  height: 54px;
  background: #f9f9f9;
  .w1200 {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .el-breadcrumb__inner a,
  .el-breadcrumb__inner.is-link {
    font-size: 14px;
    color: #6e6d72;
    font-weight: normal;
    cursor: pointer;
    &:hover {
      color: #b60005;
    }
  }
  .el-breadcrumb__item {
    .el-breadcrumb__inner {
      display: inline-block;
      max-width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
