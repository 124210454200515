<template>
  <div class="home-news-list">
    <template v-for="(item, index) in newsList">
      <news-0
        :datas="item"
        v-if="index == 0"
        :key="index"
        @viewMore="goToNewsDetail(item)"
      ></news-0>
      <news-1
        v-else-if="index > 0 && index < 3"
        :key="'item1' + index"
        :datas="item"
        @viewMore="goToNewsDetail(item)"
      ></news-1>
    </template>
    <div class="news-tupe-3-list">
      <template v-for="(item, index) in newsList">
        <news-2
          v-if="index >= 3"
          :key="'item2' + index"
          :datas="item"
          @viewMore="goToNewsDetail(item)"
        ></news-2>
      </template>
    </div>
  </div>
</template>

<script>
import news0 from "./news-0.vue";
import News1 from "./news-1.vue";
import News2 from "./news-2.vue";
export default {
  components: { news0, News1, News2 },
  name: "NewsList",
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    datas: {
      immediate: true,
      handler(val) {
        this.newsList = val;
      },
    },
  },
  data() {
    return {
      newsList: [],
    };
  },
  methods: {
    goToNewsDetail(item) {
      this.$router.push({
        path: "/newsdetail/" + item.Id,
        // params: { id: item.Id },
      });
    },
  },
};
</script>

<style lang="scss">
.home-news-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 520px;
  align-content: space-between;
  .home-news-item-1:last-child {
    margin-bottom: 0;
  }
  .home-news-item-2:first-child {
    border-radius: 10px 10px 0 0;
  }
  .news-tupe-3-list {
    box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.08);
  }
}
</style>
