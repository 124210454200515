<template>
  <div id="app" class="zjly-web-page">
    <lyt-affix :offset="0" boundary="#app">
      <div class="zjly-web-top">
        <top-header :menuData="menuData" @select="selectMenu"></top-header>
      </div>
    </lyt-affix>

    <router-view :key="routeKey"></router-view>
    <div class="zjly-web-bottom"><bottom></bottom></div>
    <el-backtop target="body">
      <div class="backtop-btn"></div>
    </el-backtop>
  </div>
</template>

<script>
import api from "./api/api";
import Bottom from "./component/bottom/bottom.vue";
import topHeader from "./component/header/top-header.vue";
import defaults from "./defaults/defaults";
export default {
  components: { topHeader, Bottom },
  name: "App",
  provide() {
    return {
      getAllMenu: () => this.menuData,
      getCurrentMenu: () => this.selectedMenu,
      updateKey: this.updateRouteKey,
    };
  },
  data() {
    return {
      menuData: [],
      selectedMenu: null,
      routeKey: 0,
    };
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "getPath",
  },
  created() {
    this.getMenu();
  },
  mounted() {
    window.addEventListener("resize", () => {
      let _width = document.body.clientWidth + "px";
      console.log("_width: ", _width);
      document.querySelector(".affix").style.width = _width;
    });
  },
  methods: {
    updateRouteKey() {
      this.routeKey++;
    },
    getPath() {
      const routeName = this.$route.name;
      let id = defaults.menuIds[routeName];
      this.selectedMenu = this.menuData.find((item) => item.Id == id);
      document.querySelector("body").scrollTo({ top: 0 });
    },
    selectMenu(item) {
      this.selectedMenu = item;
    },
    /**
     *获取菜单
     */
    getMenu() {
      let params = {
        QueryParam: {
          Status: 1,
        },
      };
      api.getMenuList(params).then((res) => {
        this.originalMenu = res.data.Results || {};
        let menus = [];
        this.originalMenu.forEach((item) => {
          // let url = defaults.menuMap[item.Id];
          let path = item.Attributes.Url || defaults.menuMap[item.Id];
          let children = item.Children || [];
          children.forEach((child) => {
            child.MenuId = child.Id;
            child.MenuName = child.Label;
            child.path = path;
          });
          let menuItem = Object.assign(
            {
              MenuId: item.Id,
              path: path,
              MenuName: item.Label,
              MenuItemList: children,
              type: 0,
            },
            item
          );
          menus.push(menuItem);
        });
        this.menuData = menus;
        let routName = this.$route.name;

        if (routName && !this.selectedMenu) {
          let menu = this.menuData.find(
            (item) => item.Id == defaults.menuIds[routName]
          );
          this.selectedMenu = menu;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/public.css";
@import "./assets/css/web.scss";
html,
body {
  width: 100%;
  height: 100%;
  min-width: 1600px;
  background: #fff;
  // overflow-x: hidden;
  // overflow: auto;
}
body {
  overflow: auto;
}
.zjly-web-page {
  // height: 100%;
  // overflow: auto;
  .affix-placeholder {
    width: 100%;
    // background: #f7f7f7;
    // box-shadow: 0 0 32px 0 rgba(24, 24, 24, 0.1);
  }
  .affix {
    // width: 100% !important;
    // right: 0;
    // width: unset !important;
  }
  .el-backtop {
    width: 50px;
    height: 50px;
    background: #b60005;
    border-radius: 6px;
    &:hover {
      background: #b60005;
      .backtop-btn {
        // background-position: center 10px;
      }
      // background: url(./assets/img/icon_hddb@2x.png) no-repeat center -3px;
    }
    .backtop-btn {
      width: 100%;
      height: 100%;
      background: url(./assets/img/icon_hddb@2x.png) no-repeat center 12px;
      background-size: 26px 26px;
    }
  }
}
.zjly-web-top {
  // background: #fff;
  background: #f7f7f7;
  box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.1);
}
.zjly-web-bottom {
  background: #eeeeee;
}
</style>
