<template>
  <div class="zjly-web-company">
    <div class="zjly-web-company-content">
      <div class="company-bar">
        <lyt-affix :offset="120">
          <locate-page :datas="sideMenuList" v-model="aboutmenus"></locate-page>
          <!-- <page-side-nav-bar
            :datas="sideMenuList"
            @select="sideBarClick"
          ></page-side-nav-bar> -->
        </lyt-affix>
      </div>
      <div style="background: #fff">
        <div class="zjly-web-company-top w1200">
          <div class="zjly-web-company-top-left">
            <h2 class="zjly-web-company-h2" id="gsjs">
              <i class="icon-bit"></i>公司介绍
            </h2>
            <div
              class="zjly-web-company-html"
              v-html="articleDetail.Content"
            ></div>
          </div>
          <div class="zjly-web-company-pic">
            <el-image :src="articleDetail.PicUrl">
              <div slot="error" class="image-error-slot"></div>
            </el-image>
          </div>
        </div>
      </div>

      <div
        class="zjly-web-company-item"
        v-for="(item, index) in articleDetail.ArticleExt"
        :key="index"
        :class="{ 'item-gray': index % 2 == 0 }"
      >
        <div class="w1200 zjly-web-company-nr">
          <h2 class="zjly-web-company-h2" :id="renderIds(item)">
            <i class="icon-bit" :class="getIcon(item)"></i>{{ item.Title }}
          </h2>
          <div
            class="zjly-web-article-detail-item-html clearfix"
            v-html="item.Content"
            v-if="isZzry(item)"
          ></div>
          <div class="zjly-web-zzry-main" v-else>
            <zzry :datas="renderPicList(item)"></zzry>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import LocatePage from "../../component/pagenavbar/locate-page.vue";
// import PageSideNavBar from "../../component/pagenavbar/page-side-nav-bar.vue";
import defaults from "../../defaults/defaults";
import zzry from "./zzry.vue";
export default {
  components: {
    zzry,
    // PageSideNavBar,
    LocatePage,
  },
  name: "Company",
  data() {
    return {
      aboutmenus: "gsjs",
      first: true,
      sideMenuList: [
        {
          // Id: "100601",
          Label: "公司介绍",
          href: "gsjs",
          Cnt: "公司介绍",
          Id: "gsjs",
        },
      ],
      articleDetail: {
        ArticleExt: [
          { Title: "企业文化", Content: "" },
          { Title: "技术优势", Content: "" },
          { Title: "产品体系", Content: "" },
        ],
      },
    };
  },
  mounted() {
    this.getAboutDetail();
  },
  methods: {
    sideBarClick(item) {
      let id = item.href;
      if (this.first) {
        this.first = false;
        return;
      }
      this.scrollToElement(id);
    },
    getIcon(item) {
      return "bgicon-" + item.Id;
    },
    renderIds(item) {
      return item.Id;
    },
    renderPicList(item) {
      let content = item.Content;
      let list = [];
      if (content) {
        list = JSON.parse(content);
      }
      return list;
    },
    isZzry(item) {
      return item.Id != "zzry";
    },
    getAboutDetail() {
      let id = defaults.menuIds.company;
      let params = {
        QueryParam: {
          Id: id,
        },
      };
      api.getArticleDetail(params).then((res) => {
        this.articleDetail = res.data.Results;
        let extList = this.articleDetail.ArticleExt || [];
        extList.forEach((item) => {
          item.Label = item.Title;
          item.href = defaults.hrefIds[item.Id];
          item.Cnt = item.Title;
          item.Id = defaults.hrefIds[item.Id];
          this.sideMenuList.push(item);
        });
      });
    },
    // 在Vue组件的methods中添加
    scrollToElement(id) {
      // 假设你要滚动到的元素的ID是'target-element'
      const el = document.getElementById(id);
      if (el) {
        // 使用window.scrollTo实现平滑滚动
        let div = document.querySelector("body");
        div.scrollTo({
          top: el.offsetTop - 112,
          behavior: "smooth",
        });
      }
    },
    bindScroll() {
      // let div = document.querySelector(".zjly-web-page");
      // div.addEventListener('scroll',()=>{
      //   let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      // }
      // )
    },
  },
};
</script>

<style lang="scss">
.zjly-web-company {
  .zjly-web-company-content {
  }
  .company-bar {
    width: 1600px;
    margin: 0 auto;
    position: relative;
    .page-side-nav-bar {
      position: absolute;
    }
  }
  .zjly-web-company-h2 {
    font-size: 28px;
    color: #181818;
    line-height: 37px;
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    .icon-bit {
      width: 28px;
      height: 28px;
      display: block;
      background: url(../../assets/img/icon_fwbz@2x.png) no-repeat center;
      background-size: 100% 100%;
      margin-right: 12px;
      &.bgicon-qywh {
        background: url(../../assets/img/icon_fwbz@2x.png) no-repeat center;
        background-size: 100% 100%;
      }
      &.bgicon-jsys {
        background: url(../../assets/img/icon_fwbz@2x.png) no-repeat center;
        background-size: 100% 100%;
      }
      &.bgicon-cptx {
        background: url(../../assets/img/icon_cptx.png) no-repeat center;
      }
      &.bgicon-zzry {
        background: url(../../assets/img/icon_zzry.png) no-repeat center;
      }
      &.bgicon-lxwm {
        background: url(../../assets/img/icon_lxwm_1.png) no-repeat center;
      }
    }
  }
  .zjly-web-company-html {
    font-size: 14px;
    color: #6e6d72;
    line-height: 32px;
    p {
      padding-bottom: 20px;
      text-indent: 28px;
    }
  }
  .zjly-web-company-top-left {
    flex: 1;
  }
  .zjly-web-company-pic {
    width: 490px;
    height: 493px;
    margin-left: 90px;
    flex: 0 auto;
    .el-image {
      width: 100%;
      height: 100%;
      .image-error-slot {
        width: 100%;
        height: 100%;
        background: #efefef;
      }
    }
  }
  .zjly-web-company-top {
    display: flex;
    padding-bottom: 80px;
    background: #fff;
  }
  .zjly-web-company-item {
    background: #fff;
    &.item-gray {
      background: #f9f9f9;
    }
  }
  .zjly-web-company-nr {
    padding: 80px 0;
  }
}
</style>
