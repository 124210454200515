<template>
  <div class="home-news-item-2" @click="viewMore">
    <div class="home-news-item-1-date p30">
      <p>{{ datas.PublishTime ? datas.PublishTime.substring(8, 10) : "" }}</p>
      <p class="home-news-item-1-date-day">
        {{ datas.PublishTime ? datas.PublishTime.substring(0, 7) : "" }}
      </p>
    </div>
    <h2 class="home-news-item-2-title zjly-ellipsis" :title="datas.Title">
      {{ datas.Title }}
    </h2>
    <a class="view-more"></a>
  </div>
</template>

<script>
export default {
  name: "News2",
  props: {
    datas: {
      type: Object,
      default: () => {
        return {
          Title: "",
          PublishTime: "",
          PicUrl: "",
          Brief: "",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    viewMore() {
      this.$emit("viewMore", this.datas);
    },
  },
};
</script>

<style lang="scss">
.home-news-item-2 {
  width: 464px;
  display: flex;
  height: 124px;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  // box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.08);
  &:hover {
    .home-news-item-1-date {
      transform: scale(0.95);

      p {
        color: #b60005;
      }
      .home-news-item-1-date-day {
        color: #b60005;
      }
    }
    h2 {
      transform: scale(0.95);
    }
  }
  &:last-child {
    border-bottom: 1px solid transparent;
    border-radius: 0 0 10px 10px;
    // box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.08);
  }
  .p30 {
    padding: 0 30px;
  }
  .view-more {
    margin-right: 30px;
    transition: 0.4s;
    &:hover {
      margin-right: 25px;
    }
  }
  .home-news-item-2-title {
    flex: 1;
    min-width: 275px;
    font-size: 18px;
    color: #181818;
    line-height: 28px;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      color: #b60005;
    }
  }
}
</style>
