<template>
  <div class="zjly-web-banner" :style="{ height: this.height + 'px' }">
    <swiper
      class="swiper gallery-thumbs"
      :options="swiperOptionThumbs"
      ref="swiperThumbs"
      @ready="handleSwiperReadied"
      :auto-update="true"
      :auto-destroy="true"
      :delete-instance-on-destroy="true"
      :cleanup-styles-on-destroy="true"
    >
      <swiper-slide
        class="slide-banner-nav"
        v-for="(item, index) in datas"
        :key="index"
      >
        <div
          class="slide-banner-nav-img"
          :style="{ 'background-image': 'url(' + item.background + ')' }"
        >
          <div class="w1200">
            <img :src="item.text" />
          </div>
          <!-- <img src="../../assets/img/banner_yyzj@2x.png" /> -->
          <!-- <img :src="item" /> -->
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "BannerNav",
  props: {
    height: {
      type: Number,
      default: 405,
    },
    datas: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptionThumbs: {
        //小手掌抓取滑动
        loop: false,
        autoplay: true, //可选选项，自动滑动
        // initialSlide:this.currentSceneIndex,
        grabCursor: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true, //防止不可点击
        // slideToClickedSlide: true,
        slidesPerView: 1,
        observer: true,
        autoScrollOffset: 0,
        navigation: {
          nextEl: ".page-btn-next",
          prevEl: ".page-btn-prev",
        },
        lazy: {
          loadPrevNext: true,
        },
        isLocated: false,
        effect: "fade",
      },
    };
  },
  methods: {
    prevPage() {},
    nextPage() {},
    handleSwiperReadied() {
      this.$refs.swiperThumbs.$swiper.update();
    },
  },
};
</script>

<style lang="scss">
.swiper {
  height: 100%;
}
.zjly-web-banner {
  height: 100%;
  overflow: hidden;
  background: #eeeeee;
  .slide-banner-nav-img {
    height: 100%;
    background-size: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
