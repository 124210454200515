export default {
  methods: {
    // 查找具有指定子节点ID的根节点，并返回该子节点的所有父节点ID数组
    findRootAndParentIds(menus, targetMenuId) {
      this.parentIds = [];
      for (let i = 0; i < menus.length; i++) {
        let treeObject = menus[i];
        const result = this.traverse(treeObject, null, targetMenuId);
        if (result.rootNode) {
          // 找到了根节点和父节点ID
          return {
            rootNode: treeObject,
            parentIds: result.parentIds.reverse(),
          };
        }
      }

      // 没有找到匹配的子节点
      return {
        rootNode: null,
        parentIds: [],
      };
    },
    /**
     * 遍历菜单，找到顶层菜单
     * @param {*} menu 菜单
     * @param {*} parentId 上级菜单
     * @param {*} targetMenuId 目标菜单
     * @returns
     */
    traverse(menu, parentId, targetMenuId) {
      if (menu.MenuId === targetMenuId) {
        // 找到目标子节点
        if (parentId) {
          this.parentIds.push(parentId);
        }
        return { rootNode: menu, parentIds: this.parentIds.slice() }; // 无需继续向上遍历
      }

      if (menu.MenuItemList) {
        for (let i = 0; i < menu.MenuItemList.length; i++) {
          let child = menu.MenuItemList[i];
          const result = this.traverse(child, menu.MenuId, targetMenuId);
          if (result.rootNode) {
            // 在子树中找到了目标子节点
            if (parentId) {
              this.parentIds.push(parentId);
            } // 添加当前父节点ID
            return {
              rootNode: result.rootNode,
              parentIds: this.parentIds.slice(),
            };
          }
        }
      }

      return { rootNode: null, parentIds: [] }; // 当前子树中未找到目标子节点
    },
  },
};
