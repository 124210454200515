<template>
  <div class="page-side-nav-bar">
    <ul>
      <li
        v-for="(item, index) in datas"
        :key="index"
        :class="{ active: selectedId == item.Id }"
        :title="item.Label"
        @click="select(item)"
      >
        <span class="zjly-ellipsis"> {{ item.Label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PageSideNavBar",
  props: {
    defaultId: {
      type: String,
      default: "",
    },
    datas: {
      type: Array,
      default: () => {},
    },
  },
  watch: {
    datas: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          if (!this.defaultId) {
            this.selectedId = val[0].Id;
            this.$emit("select", val[0]);
          } else {
            this.selectedId = this.defaultId;
            let menu = val.find((item) => item.Id == this.defaultId);
            this.$emit("select", menu);
          }
        }
      },
    },
  },
  data() {
    return {
      selectedId: "",
    };
  },
  methods: {
    select(item) {
      this.selectedId = item.Id;
      this.$emit("select", item);
    },
  },
};
</script>

<style lang="scss">
.page-side-nav-bar {
  ul {
    li {
      width: 150px;
      font-size: 16px;
      color: #999;
      line-height: 20px;
      height: 20px;
      padding-left: 20px;
      padding-bottom: 60px;
      position: relative;
      cursor: pointer;
      border-left: 2px solid #efefef;
      &:last-child {
        padding-bottom: 0;
      }
      span {
        display: block;
      }
      &.active {
        color: #b60005;
        &::before {
          background-color: #b60005;
        }
      }
      &::before {
        content: "";
        height: 20px;
        width: 2px;
        position: absolute;
        background-color: #efefef;
        left: -2px;
      }
    }
  }
}
</style>
