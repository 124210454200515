<template>
  <div class="home-news-item-0" @click="viewMore">
    <div>
      <el-image class="home-news-item-0-image" :src="datas.PicUrl" fit="cover">
        <div slot="error" class="image-error-slot"></div>
        <!-- <div slot="placeholder" class="lyt-image-load-slot">加载中...</div> -->
      </el-image>
      <p class="home-news-item-0-time">
        {{ datas.PublishTime ? datas.PublishTime.substring(0, 10) : "" }}
      </p>
      <p class="home-news-item-0-content" :title="datas.Brief">
        {{ datas.Brief }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "News0",
  props: {
    datas: {
      type: Object,
      default: () => {
        return {
          Title: "",
          Time: "",
          PicUrl: "",
          Brief: "",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    viewMore() {
      this.$emit("viewMore", this.datas);
    },
  },
};
</script>

<style lang="scss">
.home-news-item-0 {
  padding: 30px;
  width: 376px;
  height: 500px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.08);
  &:hover {
    .home-news-item-0-image {
      img {
        transform: scale(1.05);
      }
    }
    .home-news-item-0-content {
      color: #b60005;
    }
  }
  .home-news-item-0-image {
    width: 316px;
    height: 334px;
    background: #d8d8d8;
    margin-bottom: 22px;
    cursor: pointer;
    transition: all 0.3s;
    img {
      transition: all 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .home-news-item-0-time {
    font-size: 14px;
    color: #181818;
    line-height: 20px;
  }
  .home-news-item-0-content {
    font-size: 18px;
    color: #181818;
    line-height: 28px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      color: #b60005;
    }
  }
}
</style>
