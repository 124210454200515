<template>
  <div class="zzry-list">
    <div class="swiper-button-prev swiper-button-border">
      <i class="slider-btn-prev"></i>
    </div>
    <div class="swiper-button-next swiper-button-border">
      <i class="slider-btn-next"></i>
    </div>
    <swiper
      class="zzry-list-thumbs gallery-thumbs"
      :options="swiperOptionThumbs"
      ref="swiperThumbs"
      @ready="handleSwiperReadied"
      :auto-update="true"
      :auto-destroy="true"
      :delete-instance-on-destroy="true"
      :cleanup-styles-on-destroy="true"
    >
      <swiper-slide
        class="slide-banner"
        v-for="(item, index) in datas"
        :key="index"
      >
        <div class="slide-zzry-img">
          <img :src="item" style="height: 240px" @click="showBigImg(item)" />
          <!-- <el-image :src="item" style="height: 318px"> </el-image> -->
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "Zzry",
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptionThumbs: {
        // loop: true,
        autoplay: true,
        slidesPerView: "auto",
        // centeredSlides: true,
        spaceBetween: 30,
        slideClass: "custom-slide-class",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // centerSlides: true,
        // coverflowEffect: {
        //   rotate: 0,
        //   stretch: 25,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: true,
        // },
        // effect: "coverflow",
      },
    };
  },
  methods: {
    prevPage() {},
    nextPage() {},
    handleSwiperReadied() {
      this.$refs.swiperThumbs.$swiper.update();
    },
    showBigImg(img) {
      window.__vm._zoomPic.show({
        imgList: [img],
      });
    },
  },
};
</script>

<style lang="scss">
.zzry-list {
  width: 100%;
  // padding: 32px;
  position: relative;
  .swiper-button-border {
    border: 1px solid #b60005;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .swiper-button-prev {
    background: url(../../assets/img/icon_qian.png) no-repeat center;
    margin-top: -25px;
  }
  .swiper-button-next {
    background: url(../../assets/img/icon_hou.png) no-repeat center;
    margin-top: -25px;
    right: 0;
  }
  .swiper-container {
    height: 240px;
  }
  .zzry-list-thumbs {
    width: calc(100% - 160px);
    .custom-slide-class {
      height: 240px;
      // border: 1px solid #eeeeee;
      // margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 12px;
      box-sizing: border-box;
      .slide-zzry-img {
        // width: 345px;
        height: 240px;
        user-select: none;
      }
      .el-image {
        height: 100%;
      }
    }
  }
}
</style>
