<template>
  <div class="page-nav-bar">
    <a
      v-for="(item, index) in datas"
      :key="index"
      :class="{ on: selectedId == item.Id }"
      @click="select(item)"
      >{{ item.Label }}</a
    >
  </div>
</template>

<script>
import defaults from "../../defaults/defaults";
export default {
  name: "PageNavBar",
  props: {
    defaultId: {
      type: String,
      default: "",
    },
    datas: {
      type: Array,
      default: () => {},
    },
  },
  watch: {
    defaultId: {
      handler() {
        this.initMenu();
      },
    },
    datas: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          if (!this.defaultId) {
            const routeName = this.$route.name;

            if (routeName == "/about/news") {
              this.selectedId = defaults.menuIds.news;
            } else {
              this.selectedId = val[0].Id;
            }
            let menu = this.datas.find((item) => item.Id == this.selectedId);
            this.$emit("select", menu);
          } else {
            this.initMenu();
          }
        }
      },
    },
  },
  data() {
    return {
      selectedId: "",
    };
  },
  methods: {
    initMenu() {
      this.selectedId = this.defaultId;
      if (this.datas) {
        let menu = this.datas.find((item) => item.Id == this.defaultId);
        if (menu) {
          this.$emit("select", menu);
        }
      }
    },
    select(item) {
      this.selectedId = item.Id;
      this.$emit("select", item);
    },
  },
};
</script>

<style lang="scss">
.page-nav-bar {
  height: 66px;
  background: #ffffff;
  box-shadow: 0px 8px 8px 0px rgba(24, 24, 24, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  a {
    cursor: pointer;
    font-size: 16px;
    color: #181818;
    margin-right: 130px;
    &:last-child {
      margin-right: 0;
    }
    &:hover,
    &.on {
      color: #b60005;
    }
  }
}
</style>
