<template>
  <div class="card-type-5">
    <div class="card-type-5-main">
      <i v-if="datas.IsNew == 1" class="card-type-new-bit">NEW</i>
      <el-image
        class="card-type-5-image"
        :src="datas.PicUrl"
        @click="goTodetail"
      >
        <div slot="error" class="image-error-slot"></div>
        <!-- <div slot="placeholder" class="lyt-image-load-slot">加载中...</div> -->
      </el-image>
      <div class="card-type-5-main-content">
        <h3 class="zjly-ellipsis" @click="goTodetail">{{ datas.Title }}</h3>
        <div class="card-type-content">{{ datas.Brief }}</div>
        <div class="card-type5-bottom">
          <a @click="goTodetail">了解详情<i></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardType5",
  props: {
    datas: {
      type: Object,
      default: () => {
        return {
          Title: "视频联网共享",
          PicUrl: "",
          Content:
            "系统遵从公安部“一机一档”系统建设思路，以及省、市的“一机一档”建设要求，立足公安实战需求，以设备档案全生命周期管理应用",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    goTodetail() {
      this.$emit("goToDetail", this.datas);
    },
  },
};
</script>

<style lang="scss">
.card-type-5 {
  width: 1200px;
  height: 360px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  background: #ffffff;
  box-sizing: border-box;
  margin-bottom: 24px;
  &:hover {
    box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.08);
    img {
      transform: scale(1.05);
    }
  }
  .card-type-5-main {
    display: flex;
    height: 100%;
    position: relative;
    .card-type-new-bit {
      color: #ffffff;
      font-size: 12px;
      background: url(../../assets/img/img_hot.png) no-repeat;
      border-radius: 0 0 0 12px;
      position: absolute;
      right: 4px;
      top: 4px;
      width: 32px;
      // padding: 0 5px 0 10px;
      height: 38px;
      line-height: 53px;
      text-align: center;
      z-index: 10;
    }
  }
  .card-type-5-main-content {
    padding: 0 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .card-type-5-image {
    width: 440px;
    height: 322px;
    flex: 0 auto;
    margin-top: 18px;
    margin: 18px 0 0 40px;
    cursor: pointer;
    img {
      transition: all 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }
    .image-error-slot {
      width: 100%;
      height: 100%;
      background: #efefef;
    }
  }
  h3 {
    font-size: 22px;
    color: #181818;
    line-height: 29px;
    // height: 29px;
    text-align: left;
    margin-bottom: 20px;
    padding-top: 44px;
    cursor: pointer;
    &:hover {
      color: #b60005;
    }
  }
  .card-type-content {
    height: 64px;
    // flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-bottom: 56px;
    color: #6e6d72;
  }
  .card-type5-bottom {
    a {
      display: inline-block;
      width: 116px;
      height: 52px;
      line-height: 52px;
      text-align: center;
      border-radius: 26px;
      border: 1px solid #b60005;
      font-size: 14px;
      color: #b60005;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      cursor: pointer;
      &:hover {
        border: 1px solid #c7000b;
        background-color: #c7000b;
        color: #fff;
      }
    }
  }
}
</style>
