import Vue from "vue";
import Router from "vue-router";
import Home from "../views/home/home.vue";
import Product from "../views/product/product.vue";
import ErrorPage from "../component/errorpage/errorPage.vue";
import ProductDetail from "../views/product/product-detail.vue";
import About from "../views/about/about.vue";
import Company from "../views/about/company.vue";
import News from "../views/about/news.vue";
import Joinus from "../views/about/joinus.vue";
import Server from "../views/server/server.vue";
import ServerGuarantee from "../views/server/server-guarantee.vue";
import Suggest from "../views/server/server-suggest.vue";
import NetWork from "../views/server/server-net.vue";
import Solution from "../views/solution/solution.vue";
import SolutionDetail from "../views/solution/solution-detail.vue";
import NewsDetail from "../views/about/news-detail.vue";
import CaseDetail from "../views/case/case-detail.vue";
Vue.use(Router);
// 每个路由都需要映射到一个组件。
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/product",
    name: "product",
    component: Product,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/productdetail/:id",
    name: "productdetail",
    component: ProductDetail,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/solution",
    name: "solution",
    component: Solution,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/solutiondetail/:id",
    name: "solutiondetail",
    component: SolutionDetail,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/case",
    name: "case",
    component: ErrorPage,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/casedetail/:id",
    name: "casedetail",
    component: CaseDetail,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: {
      title: "浙江立元科技有限公司",
    },
    children: [
      {
        path: "company",
        name: "about",
        component: Company,
        meta: {
          title: "浙江立元科技有限公司",
        },
      },
      {
        path: "news",
        name: "/about/news",
        component: News,
        meta: {
          title: "浙江立元科技有限公司",
        },
      },
      {
        path: "joinus",
        name: "about",
        component: Joinus,
        meta: {
          title: "浙江立元科技有限公司",
        },
      },
    ],
  },
  {
    path: "/newsdetail/:id",
    name: "newsdetail",
    component: NewsDetail,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/joinus",
    name: "joinus",
    component: ErrorPage,
    meta: {
      title: "浙江立元科技有限公司",
    },
  },
  {
    path: "/server",
    name: "server",
    component: Server,
    meta: {
      title: "浙江立元科技有限公司",
    },
    children: [
      {
        path: "serverguarantee",
        name: "server",
        component: ServerGuarantee,
      },
      {
        path: "network",
        name: "server",
        component: NetWork,
      },
      {
        path: "suggest",
        name: "server",
        component: Suggest,
      },
    ],
  },

  // 404页面
  {
    path: "/error",
    component: ErrorPage,
    name: "ErrorPage",
    meta: {
      title: "404",
    },
  },
  {
    path: "/admin",
    name: "首页配置",
    component: () => {
      import("../views/admin/admin.vue");
    },
  },
];
const router = new Router({
  mode: "history",
  base: "/app-website/lyte/",
  // scrollBehavior(to, from, savedPosition) {
  //   //使用前端路由，当切换到新路由时，想要页面滚到顶部，或者是保持原先的滚动位置，就像重新加载页面那样。 vue-router 能做到，而且更好，它让你可以自定义路由切换时页面如何滚动。
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
  routes: routes,
});
// 解决重复点击导航时，控制台出现报错
const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
export default router;
