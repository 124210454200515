<template>
  <div class="zjly-web-product">
    <banner-nav :datas="bannerList" :height="300"></banner-nav>
    <page-nav-bar
      :datas="menuList"
      @select="navBarClick"
      :defaultId="second"
    ></page-nav-bar>
    <div class="zjly-web-product-content" id="content">
      <lyt-affix :offset="120" boundary="#content">
        <page-side-nav-bar
          :datas="sideMenu"
          :defaultId="third"
          @select="sideBarClick"
          v-if="sideMenu.length"
        ></page-side-nav-bar>
      </lyt-affix>
      <div class="zjly-web-product-list">
        <lyt-no-data v-if="proList.length == 0"></lyt-no-data>
        <div v-for="(item, index) in proList" :key="index">
          <card-type-5
            :datas="item"
            v-if="item.IsTop == 1"
            :key="index"
            @goToDetail="openDetail"
          ></card-type-5>
        </div>
        <div class="pro-notop-list">
          <template v-for="(item, index) in proList">
            <card-type-3
              v-if="item.IsTop != 1"
              :datas="item"
              :key="index"
              @goToDetail="openDetail"
            ></card-type-3>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import bannerNav from "../../component/banner/banner-nav.vue";
import CardType3 from "../../component/card/card-type-3.vue";
import CardType5 from "../../component/card/card-type-5.vue";
import PageNavBar from "../../component/pagenavbar/page-nav-bar.vue";
import PageSideNavBar from "../../component/pagenavbar/page-side-nav-bar.vue";
import menuMixins from "../../mixins/menuMixins";
import proMixins from "../../mixins/proMixins";
export default {
  name: "Product",
  components: {
    bannerNav,
    PageNavBar,
    PageSideNavBar,
    CardType3,
    CardType5,
  },

  mixins: [menuMixins, proMixins],
  data() {
    return {
      menuList: [],
      selectedSecondMenuItem: {},
      selectedThirdMenuItem: {},
      proList: [],
      second: "", //默认选中二级
      third: "", //默认选中三级
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        document.querySelector("body").scrollTo({ top: 0 });
        // console.log("val:route ", val);
      },
    },
    "$route.params.productType": {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.second = val.second;
          this.third = val.third;
        }
      },
    },
    sideMenu: {
      handler(val) {
        if (val && val.length == 0) {
          this.selectedThirdMenuItem = null;
        }
      },
    },
  },
  computed: {
    sideMenu() {
      return this.selectedSecondMenuItem.Children || [];
    },
    productType() {
      return this.$route.params.productType;
    },
  },
  created() {
    let productType = localStorage.getItem("productType");
    if (productType) {
      let data = JSON.parse(productType);
      this.second = data.second;
    }
    localStorage.removeItem("productType");
  },
  mounted() {},
  methods: {
    /**
     * 产品二级导航-点击
     * @param {*} menu
     */
    navBarClick(menu) {
      console.log("menupro: ", menu);
      this.selectedSecondMenuItem = menu;
      if (
        !this.selectedSecondMenuItem.Children ||
        this.selectedSecondMenuItem.Children.length == 0
      ) {
        this.getProList(menu.Id);
      }
    },
    /**
     * 产品三级级导航-点击
     * @param {*} menu
     */
    sideBarClick(menu) {
      this.selectedThirdMenuItem = menu;
      this.getProList(this.selectedThirdMenuItem.Id);
    },
    /**
     * 获取产品列表
     */
    getProList(id) {
      let params = {
        QueryParam: {
          MenuId: id,
          Status: 1, //后面换成1
        },
      };
      api.getArticleList(params).then((res) => {
        let data = res.data.Results || [];
        this.proList = data;
      });
    },
    openDetail(item) {
      console.log("item: ", item);
      let links = this.getLinks();
      links.push({
        MenuId: item.Id,
        Label: item.Title,
        type: 0,
      });
      this.$router.push({ path: "productdetail/" + item.Id });
      // this.$router.push({
      //   name: "productdetail",
      //   params: { id: item.Id, links: links },
      // });
    },
  },
};
</script>

<style lang="scss">
.zjly-web-product-content {
  width: 1200px;
  margin: 0 auto 56px;
  position: relative;
  min-height: 500px;
  .zjly-web-product-list {
    .pro-notop-list {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .card-type-3 {
        margin-right: 24px;
        margin-bottom: 24px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        h3 {
          text-align: center;
        }
      }
    }
  }
  .page-side-nav-bar {
    width: 206px;
    left: -206px;
    position: absolute;
    top: 0;
  }
}
</style>
