<template>
  <div class="zjly-web-article-detail">
    <banner-nav :datas="bannerList" :height="300"></banner-nav>
    <breadcrumb :datas="linksList"></breadcrumb>
    <div class="zjly-web-article-detail-content">
      <div class="zjly-web-article-detail-top w1200">
        <div class="zjly-web-article-detail-item-nr">
          <h2>{{ detailData.Title }}</h2>
          <div v-html="detailData.Content"></div>
        </div>
      </div>
      <div
        class="zjly-web-article-detail-item"
        v-for="(item, index) in detailData.ArticleExt"
        :class="{ 'item-gray': index % 2 == 0 }"
        :key="index"
      >
        <div class="zjly-web-article-detail-item-nr w1200">
          <div class="zjly-web-article-detail-title">
            <h2>{{ item.Title }}</h2>
          </div>
          <div
            class="zjly-web-article-detail-item-html clearfix"
            v-html="item.Content"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import bannerNav from "../../component/banner/banner-nav.vue";
import Breadcrumb from "../../component/pagenavbar/breadcrumb.vue";
import proMixins from "../../mixins/proMixins";
export default {
  name: "ProductDetail",
  components: { bannerNav, Breadcrumb },
  mixins: [proMixins],
  data() {
    return {
      detailData: {},
      linksList: [],
    };
  },
  mounted() {
    this.getDetail();
    // let links = this.$route.params.links;
    // if (!links) {
    //   let sesstionLinks = localStorage.getItem("solutionDetailLinks");
    //   if (sesstionLinks) {
    //     links = JSON.parse(sesstionLinks);
    //   }
    // } else {
    //   localStorage.setItem("solutionDetailLinks", JSON.stringify(links));
    // }
    // this.linksList = links;
  },
  watch: {
    allMenu() {
      this.getNavLinks();
    },
  },
  methods: {
    // 获取面包屑导航
    getNavLinks() {
      let fullPath = this.detailData.FullPath
        ? this.detailData.FullPath.split("$")
        : [];
      if (fullPath.length === 0) return; // 如果路径为空，直接返回
      if (fullPath.length > 0) {
        this.linksList = this.getMenusByPath(
          fullPath,
          this.allMenu,
          this.detailData
        );
      }
    },
    getMenusByPath(fullPath, allMenu, article) {
      console.log(fullPath);
      if (fullPath.length === 0) return; // 如果路径为空，直接返回
      let links = [];
      // 遍历fullPath中的每个ID
      for (let i = 0; i < fullPath.length; i++) {
        const menu = this.getMenuById(allMenu, fullPath[i]);
        if (menu) {
          // 确保找到了菜单项
          const link =
            i === 0 ? menu : Object.assign({ type: (i + 1).toString() }, menu);
          links.push(link);
        }
      }
      links.push({
        MenuId: article.Id,
        Label: article.Title,
        type: 0,
      });
      return links;
    },
    getDetail() {
      let id = this.$route.params.id;
      if (!id) {
        let articleId = localStorage.getItem("productId");
        if (articleId) {
          id = articleId;
        }
      } else {
        localStorage.setItem("productId", id);
      }

      let params = {
        QueryParam: {
          Id: id,
        },
      };
      api.getArticleDetail(params).then((res) => {
        this.detailData = res.data.Results;
        this.getNavLinks();
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/css/web.scss";
</style>
