<template>
  <div>服务保证</div>
</template>

<script>
export default {
  name: "ServerGuarantee",
};
</script>

<style></style>
