<template>
  <div class="card-type-2">
    <div class="card-type-2-main">
      <el-image class="card-type-2-image" :src="datas.PicUrl" @click="viewMore">
        <div slot="error" class="image-error-slot"></div>
        <!-- <div slot="placeholder" class="lyt-image-load-slot">加载中...</div> -->
      </el-image>
      <div class="card-type-2-main-content">
        <h3 class="zjly-ellipsis" @click="viewMore">{{ datas.Title }}</h3>
        <div class="card-type-content">{{ datas.Brief }}</div>
        <div class="card-type-bottom">
          <a @click="viewMore">了解更多<i></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardType2",
  props: {
    isLazy: {
      type: Boolean,
      default: true,
    },
    datas: {
      type: Object,
      default: () => {
        return {
          Title: "",
          PicUrl: "",
          Content: "",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    viewMore() {
      this.$emit("viewMore", this.datas);
    },
  },
};
</script>

<style lang="scss">
.card-type-2 {
  width: 372px;
  height: 500px;
  // padding: 42px 36px 0;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 22px 0px rgba(24, 24, 24, 0.08);
  // box-shadow: 0 0 20px 0 rgba(24, 24, 24, 0.08);
  // border: #ccc 1px solid;
  border-radius: 10px;
  text-align: left;
  .card-type-2-main {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .card-type-2-main-content {
    padding: 0 36px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .card-type-2-image {
    width: 100%;
    height: 233px;
    border-radius: 10px 10px 0 0;
    flex: 0 auto;
    margin-bottom: 42px;
    cursor: pointer;
    img {
      transition: all 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }
    .image-error-slot {
      width: 100%;
      height: 100%;
      background: #efefef;
    }
  }
  h3 {
    font-size: 22px;
    color: #181818;
    line-height: 29px;
    // height: 29px;
    text-align: left;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
      color: #b60005;
    }
  }
  .card-type-content {
    height: 63px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-bottom: 57px;
    color: #6e6d72;
  }
  .card-type-bottom {
    flex: 0 auto;
    height: 50px;
    text-align: right;
  }
}
</style>
