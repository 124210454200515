<template>
  <div class="zjly-web-solution bg-gray">
    <banner-nav :datas="bannerList" :height="300"></banner-nav>
    <div class="zjly-web-nomal-nav-bar">
      <div class="w1200"><breadcrumb :datas="linksList"></breadcrumb></div>
    </div>
    <div class="zjly-web-solution-content w1200">
      <div class="zjly-web-solution-top">
        <h2>概述</h2>
        <div class="zjly-web-solution-bref">
          {{ currentMenu.Attributes.Description }}
        </div>
      </div>
      <div class="zjly-web-solution-list">
        <card-type-4
          v-for="(item, index) in ArticleList"
          :key="index"
          :datas="item"
          @goToDetail="gotoSolutionDetail(item)"
        ></card-type-4>
      </div>
    </div>
  </div>
</template>

<script>
import bannerNav from "../../component/banner/banner-nav.vue";
import proMixins from "../../mixins/proMixins";
// import defaults from "../../defaults/defaults";
import Breadcrumb from "../../component/pagenavbar/breadcrumb.vue";
import CardType4 from "../../component/card/card-type-4.vue";
export default {
  name: "Solution",
  components: {
    bannerNav,
    Breadcrumb,
    CardType4,
  },
  mixins: [proMixins],
  data() {
    return {
      menuList: [],
      selectedSecondMenuItem: null,
      selectedThirdMenuItem: null,
      linksList: [],
      ArticleList: [],
    };
  },
  methods: {
    afterGetCurrentMenu() {
      this.linksList = [
        {
          MenuId: this.currentMenu.Id,
          Label: this.currentMenu.Label,
          type: 0,
        },
      ];
      this.getArticleList(this.currentMenu.Id);
    },
    /**
     * 解决方案详情
     * @param {} item
     */
    gotoSolutionDetail(item) {
      this.$router.push({
        path: "/solutiondetail/" + item.Id,
      });
    },
  },
};
</script>

<style lang="scss">
.zjly-web-solution {
  padding-bottom: 80px;
  &.bg-gray {
    background: #f9f9f9;
  }
  .zjly-web-solution-content {
    background: #fff;
    padding: 42px 50px;
    .zjly-web-solution-top {
      h2 {
        font-size: 28px;
        color: #181818;
        line-height: 37px;
        padding-bottom: 21px;
      }
      .zjly-web-solution-bref {
        font-size: 16px;
        color: #6e6d72;
        line-height: 32px;
      }
    }
    .zjly-web-solution-list {
      .card-type-4 {
        margin-top: 48px;
      }
    }
  }
}
</style>
