<template>
  <div class="zjly-web-home">
    <banner :datas="bannerList"></banner>
    <div class="bg-gray">
      <div class="zjly-web-home-content" style="padding-bottom: 0">
        <div
          class="zjly-web-home-content-title"
          v-animate-onscroll="'animate__animated animate__fadeInDown'"
          style="margin-bottom: 28px"
        >
          <h2>{{ proContent.Name }}</h2>
          <p>{{ proContent.Brief }}</p>
        </div>
        <!-- <product-list></product-list> -->
      </div>
      <div
        class="zjly-web-home-content-pro"
        v-animate-onscroll="'animate__animated animate__fadeInUp'"
      >
        <product-list :datas="proContent.HomepageExtList"></product-list>
      </div>
    </div>

    <div class="bg-color-pro">
      <div class="zjly-web-home-content">
        <div
          class="zjly-web-home-content-title"
          v-animate-onscroll="'animate__animated animate__fadeInDown'"
        >
          <!-- <h2>场景数字化解决方案</h2>
          <p>以场景为核心，打造数字化新体验</p> -->
          <h2>{{ solutionContent.Name }}</h2>
          <p>{{ solutionContent.Brief }}</p>
        </div>
        <picture-puzzle
          v-animate-onscroll="'animate__animated animate__fadeInUp'"
          :datas="solutionContent.HomepageExtList"
        ></picture-puzzle>
        <div class="zjly-web-home-case-btn">
          <a @click="viewMoreCase">查看更多</a>
        </div>
      </div>
    </div>
    <div class="bg-gray">
      <div class="zjly-web-home-content" style="padding-bottom: 0">
        <div
          class="zjly-web-home-content-title"
          style="margin-bottom: 28px"
          v-animate-onscroll="'animate__animated animate__fadeInDown'"
        >
          <!-- <h2>核心案例</h2>
        <p>视频图像信息实战应用的创新与探索</p> -->
          <h2>{{ caseContent.Name }}</h2>
          <p>{{ caseContent.Brief }}</p>
        </div>
      </div>
      <div
        class="zjly-web-home-content-pro"
        v-animate-onscroll="'animate__animated animate__fadeInUp'"
      >
        <case-list :datas="caseContent.HomepageExtList"></case-list>
      </div>
    </div>

    <!-- v-animate-onscroll="'animate__animated animate__bounceInUp'" -->
    <div>
      <div class="zjly-web-home-content">
        <div
          class="zjly-web-home-content-title"
          v-animate-onscroll="'animate__animated animate__fadeInDown'"
        >
          <h2>公司新闻</h2>
          <p>时刻分享，公司动态不断</p>
        </div>
        <news-list
          :datas="newsList"
          v-animate-onscroll="'animate__animated animate__fadeInUp'"
        ></news-list>
      </div>
    </div>
    <div class="zjly-web-home-bottom">
      <h3 v-animate-onscroll="'animate__animated animate__fadeInDown'">
        视频图像应用专家
      </h3>
      <a
        @click="gotoAbout"
        v-animate-onscroll="'animate__animated animate__fadeInUp'"
        >关于我们</a
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import api from "../../api/api";
import Banner from "../../component/banner/banner.vue";
import defaults from "../../defaults/defaults";
import CaseList from "./case-list.vue";
import NewsList from "./news-list.vue";
import PicturePuzzle from "./picture-puzzle.vue";
import ProductList from "./product-list.vue";

import AnimateOnscroll from "../../assets/js/index";
export default {
  name: "Home",
  inject: ["getCurrentMenu", "getAllMenu"],
  components: {
    Banner,
    ProductList,
    CaseList,
    NewsList,
    PicturePuzzle,
  },
  data() {
    return {
      pageData: [], //首页内容
      proContent: {}, //产品中心内容
      solutionContent: {}, //解决方案内容
      caseContent: {}, //案例方案内容
      bannerList: [],
      newsList: [], //新闻列表
      root: document.body,
    };
  },
  computed: {
    allMenu() {
      return this.getAllMenu();
    },
  },
  watch: {
    allMenu: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          let menu = val.find((item) => item.MenuId == "1001");
          this.bannerList = menu.Attributes.BannerList || [];
        }
      },
    },
  },
  created() {
    this.getContent();
    AnimateOnscroll.install(Vue);
  },
  mounted() {
    this.getNewsList();
    // document.body.addEventListener("scroll", (e) => {
    //   console.log("e: ", e);
    // });
  },
  methods: {
    gotoAbout() {
      this.$router.push({ path: "/about" });
    },
    viewMoreCase() {
      this.$router.push({ path: "/solution" });
    },
    /**
     * 获取首页内容
     */
    getContent() {
      api.getHomeContent().then((res) => {
        this.pageData = res.data.Results || [];
        this.proContent = this.getDataByMenuId("1002");
        this.solutionContent = this.getDataByMenuId("1003");
        this.caseContent = this.getDataByMenuId("1004");
      });
    },
    /**
     * 根据菜单Id获取对应内容
     * @param {*} menuId
     */
    getDataByMenuId(menuId) {
      let data = this.pageData.find((item) => item.MenuId == menuId);
      return data || {};
    },
    getNewsList() {
      let params = {
        QueryParam: {
          MenuId: defaults.menuIds.news, //菜单Id
          Status: "1", //发布状态 1已发布 0未发布
        },
        PageRecordNum: "7",
        RecordStartNo: "0",
      };
      api.getArticleList(params).then((res) => {
        let data = res.data.Results || [];
        this.newsList = data;
      });
    },
  },
};
</script>

<style lang="scss">
.zjly-web-home {
  width: 100%;
  .zjly-web-home-content-pro {
    width: 1265px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: 15px;
    padding-bottom: 40px;
  }
  .zjly-web-home-content {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
  }
  .bg-gray {
    background: #f7f7f7;
  }
  .bg-color-pro {
    background: #fff;
  }
  .bg-color-gray {
    background: #f9f9f9;
  }
  .zjly-web-home-content-title {
    text-align: center;
    margin-bottom: 60px;
    h2 {
      height: 42px;
      font-size: 32px;
      color: #181818;
      line-height: 42px;
      margin-bottom: 20px;
    }
    p {
      height: 21px;
      font-size: 16px;
      color: #6e6d72;
      line-height: 21px;
    }
  }
  .zjly-web-home-case-btn {
    text-align: center;
    a {
      display: inline-block;
      width: 116px;
      height: 52px;
      line-height: 52px;
      text-align: center;
      border-radius: 26px;
      border: 1px solid #b60005;
      font-size: 14px;
      color: #b60005;
      transition: 0.4s;
      cursor: pointer;
      &:hover {
        border: 1px solid #c7000b;
        background-color: #c7000b;
        color: #fff;
      }
    }
  }

  .zjly-web-home-bottom {
    background: url(../../assets/img/bg_yyzj.png) no-repeat center #aa0004;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 328px;
    h3 {
      font-size: 36px;
      color: #ffffff;
      line-height: 48px;
      margin-bottom: 40px;
    }
    a {
      width: 135px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: #b60005;
      background: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
