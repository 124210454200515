<template>
  <div>
    <div class="zjly-bottom">
      <div class="zjly-bottom-content">
        <div class="zjly-bo-part1">
          <div class="logo">
            <img src="../../assets/img/logo_2.png" height="38" />
          </div>
          <div class="ewm">
            <img src="../../assets/img/ewm.png" />
            <p>关注“浙江立元科技”</p>
          </div>
        </div>
        <div class="zjly-bo-part2">
          <div class="zjly-bo-part2-nav-item">
            <h3>产品中心</h3>
            <ul>
              <li
                v-for="(item, index) in proMenu"
                :key="index"
                @click="goToProDetail(item)"
              >
                {{ item.Label }}
              </li>
            </ul>
          </div>
          <div class="zjly-bo-part2-nav-item">
            <h3>服务支持</h3>
            <ul>
              <li @click="gotoFwbz">服务保障</li>
              <li @click="gotoFwwd">服务网点</li>
              <li @click="gototsjy">投诉与建议</li>
            </ul>
          </div>
          <div class="zjly-bo-part2-nav-item">
            <h3>关于我们</h3>
            <ul>
              <li
                v-for="(item, index) in aboutMenu"
                :key="index"
                @click="goToAboutDetail(item)"
              >
                {{ item.Label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="zjly-bo-part3">
          <div class="zjly-bo-part3-item">
            <h3>联系我们</h3>
            <h4>{{ phone }}</h4>
          </div>
          <div class="zjly-bo-part3-item">
            <h3>企业邮箱</h3>
            <p>{{ email }}</p>
          </div>
          <div class="zjly-bo-part3-item">
            <h3>联系地址</h3>
            <p>{{ address }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="zjly-bo-links">
      <!-- 常用链接：<a>百度翻译百度翻译</a> -->
    </div>
    <div class="zjly-bo-foot">
      © 浙江立元科技有限公司 2019-2024 浙ICP备20007361号
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import defaults from "../../defaults/defaults";
export default {
  name: "Bottom",
  inject: ["getAllMenu", "updateKey"],
  data() {
    return {
      proMenu: [],
      serverMenu: [],
      aboutMenu: [],
      phone: "",
      email: "",
      address: "",
    };
  },
  computed: {
    allMenu() {
      return this.getAllMenu();
    },
  },
  watch: {
    allMenu: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.renderMenu();
        }
      },
    },
  },
  mounted() {
    // this.updateKey;
    // this.renderMenu();
    this.getOtherConfig();
  },
  methods: {
    goToProDetail(item) {
      if (this.$route.name == "product") {
        let data = JSON.stringify({ second: item.MenuId });
        localStorage.setItem("productType", data);
        this.updateKey();
        // this.$router.push({
        //   name: "product",
        //   params: { productType: { second: item.MenuId } },
        // });
        return;
      }
      this.$router.push({
        name: "product",
        params: { productType: { second: item.MenuId } },
      });
    },
    goToServerDetail(item) {
      this.$router.push({
        name: "server",
        params: { id: item.MenuId },
      });
    },
    gotoFwbz() {
      localStorage.setItem("serverId", "fwbz");
      this.updateKey();
      this.$router.push({
        path: "/server",
      });
    },
    gotoFwwd() {
      localStorage.setItem("serverId", "fwwd");
      this.updateKey();
      this.$router.push({
        path: "/server",
      });
    },
    gototsjy() {
      localStorage.setItem("serverId", "tsjy");
      this.updateKey();
      this.$router.push({
        path: "/server",
      });
    },
    goToAboutDetail(item) {
      localStorage.setItem("aboutId", item.Id);
      this.updateKey();
      this.$router.push({
        path: item.Attributes.Url,
      });
    },

    renderMenu() {
      let pro = this.allMenu.find(
        (item) => item.MenuId == defaults.menuIds["product"]
      );

      let server = this.allMenu.find(
        (item) => item.MenuId == defaults.menuIds["server"]
      );

      let about = this.allMenu.find(
        (item) => item.MenuId == defaults.menuIds["about"]
      );
      this.proMenu = (pro && pro.MenuItemList) || [];
      this.serverMenu = (server && server.MenuItemList) || [];
      this.aboutMenu = (about && about.MenuItemList) || [];
    },
    /**
     *获取产品中心菜单
     */
    getProMenu() {
      let params = {
        QueryParam: {
          Status: 1,
          Parent: "1002",
        },
      };
      api.getMenuList(params).then((res) => {
        let menus = res.data.Results || {};

        menus.forEach((item) => {
          item.path = "/" + item.Attributes.Url;
        });
        this.proMenu = menus;
      });
    },
    /**
     * 获取联系我们配置
     */
    getOtherConfig() {
      api.getOtherConfig().then((res) => {
        let data = res.data.Results || [];
        let lxwmData = this.getObjById("1", data);
        let emailData = this.getObjById("2", data);
        let addrData = this.getObjById("3", data);
        this.phone = lxwmData.Value;
        this.email = emailData.Value;
        this.address = addrData.Value;
      });
    },
    getObjById(id, data) {
      let obj = data.find((item) => item.Id == id);
      return obj;
    },
  },
};
</script>

<style lang="scss">
.zjly-bottom {
  width: 1200px;
  margin: 0 auto;
  padding: 62px 0 62px;
  .zjly-bottom-content {
    display: flex;
    .zjly-bo-part1 {
      width: 150px;
      flex: 0 auto;
      text-align: center;
      color: #666;
      margin-right: 190px;
      .logo {
        margin-bottom: 40px;
      }
      p {
        height: 35px;
        line-height: 35px;
        font-size: 13px;
        color: #666;
      }
    }
    .zjly-bo-part2 {
      flex: 1;
      display: flex;
      .zjly-bo-part2-nav-item {
        margin-right: 140px;
        h3 {
          font-weight: bold;
          font-size: 16px;
          color: #666666;
          height: 38px;
          line-height: 38px;
          margin-bottom: 40px;
        }
        ul {
          li {
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            height: 19px;
            cursor: pointer;
            margin-bottom: 15px;
            a {
              color: #666666;
              &:hover {
                color: #b60005;
              }
            }
            &:hover {
              color: #b60005;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .zjly-bo-part3 {
      width: 190px;
      flex: 0 auto;
      .zjly-bo-part3-item {
        margin-bottom: 54px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      h3 {
        font-weight: bold;
        font-size: 16px;
        color: #666666;
        line-height: 21px;
        height: 38px;
        line-height: 38px;
      }
      h4 {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #666666;
        line-height: 19px;
      }
      p {
        font-size: 14px;
        color: #666666;
        line-height: 21px;
      }
    }
  }
  .zjly-bo-links {
    margin: 42px 0 24px;
    color: #666;
    a {
      color: #666;
      cursor: pointer;
      margin-right: 20px;
      &:hover {
        color: #b60005;
      }
    }
  }
}
.zjly-bo-foot {
  text-align: center;
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  background: #454545;
}
</style>
