<template>
  <div class="zjly-web-joins">
    <div class="zjly-web-joins-content w1200">
      <h3 class="zjly-web-job-head">在招职位</h3>
      <ul class="joblist">
        <li class="joblist-item" v-for="(item, index) in jobList" :key="index">
          <h4 class="oblist-item-title">
            <span class="job-title">{{ item.Title }}</span>
            <span class="job-date"
              >发布于{{
                item.PublishTime ? item.PublishTime.substring(0, 10) : ""
              }}</span
            >
          </h4>
          <div class="job-content clearfix" v-html="item.Content"></div>
        </li>
      </ul>
      <h3 class="zjly-web-job-head icon-contact">联系我们</h3>
      <ul class="zjly-web-job-bottom">
        <li
          v-for="(item, index) in contactData"
          :key="index"
          :class="item.iconCls"
        >
          <h5>{{ item.title }}</h5>
          <p>{{ item.value }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import defaults from "../../defaults/defaults";
export default {
  name: "JoinUs",
  data() {
    return {
      jobList: [],
      contactData: [
        {
          title: "HR刘小姐",
          value: "0571-87209026",
          iconCls: "bgicon-phone",
        },
        {
          title: "简历发至",
          value: "rdhr@ziliyuan.com",
          iconCls: "bgicon-email",
        },
      ],
    };
  },
  mounted() {
    this.getJobList();
    this.getOtherConfig();
  },
  methods: {
    getJobList() {
      let params = {
        QueryParam: {
          MenuId: defaults.menuIds.joblist, //菜单Id
          Status: "1", //发布状态 1已发布 0未发布
        },
        RecordStartNo: (this.currentPage - 1) * this.pageSize,
        PageRecordNum: this.pageSize,
      };
      api.getArticleList(params).then((res) => {
        this.jobList = res.data.Results || [];
      });
    },
    getObjById(id, data) {
      let obj = data.find((item) => item.Id == id);
      return obj;
    },
    /**
     * 获取联系我们配置
     */
    getOtherConfig() {
      api.getOtherConfig().then((res) => {
        let data = res.data.Results || [];
        let hrData = this.getObjById("5", data);
        let yxData = this.getObjById("6", data);
        this.contactData[0].title = hrData.Name;
        this.contactData[0].value = hrData.Value;
        this.contactData[1].title = yxData.Name;
        this.contactData[1].value = yxData.Value;
      });
    },
  },
};
</script>

<style lang="scss">
.zjly-web-joins {
  background: #fff;
  padding: 0 0 80px;
  .zjly-web-job-head {
    font-size: 28px;
    color: #181818;
    line-height: 37px;
    background: url(../../assets/img/icon_zzzw.png) no-repeat left center;
    padding-left: 35px;
    &.icon-contact {
      background: url(../../assets/img/icon_lxwm.png) no-repeat left center;
    }
  }
  .joblist {
    padding: 46px 0 0;
    .joblist-item {
      margin-bottom: 55px;
      .oblist-item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 21px;
        border-bottom: 1px solid #dddddd;
        .job-title {
          font-size: 22px;
          color: #181818;
          line-height: 29px;
        }
        .job-date {
          font-size: 16px;
          color: #b1b1b1;
        }
      }
      .job-content {
        font-size: 16px;
        color: #6e6d72;
        line-height: 32px;
      }
    }
  }
  .zjly-web-job-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    li {
      flex: 1;
      margin-right: 48px;
      height: 128px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.08);
      &:hover {
        box-shadow: 0px 0px 20px 0px rgba(24, 24, 24, 0.3);
      }
      &.bgicon-phone {
        background: url(../../assets/img/icon_gfrx.png) no-repeat right bottom;
      }
      &.bgicon-email {
        background: url(../../assets/img/icon_youx.png) no-repeat right bottom;
      }
      &:last-child {
        margin-right: 0;
      }
      h5 {
        font-size: 20px;
        color: #181818;
        line-height: 26px;
        margin-bottom: 23px;
      }
      p {
        font-size: 26px;
        color: #181818;
        line-height: 35px;
      }
    }
  }
}
</style>
