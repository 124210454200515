<template>
  <div class="home-news-item-1">
    <div class="home-news-item-1-top" @click="viewMore">
      <div class="home-news-item-1-date">
        <p>{{ datas.PublishTime ? datas.PublishTime.substring(8, 10) : "" }}</p>
        <p class="home-news-item-1-date-day">
          {{ datas.PublishTime ? datas.PublishTime.substring(0, 7) : "" }}
        </p>
      </div>
      <a class="view-more"></a>
    </div>
    <div class="home-news-item-1-bottom">
      <h2
        class="home-news-item-1-title zjly-ellipsis"
        :title="datas.Title"
        @click="viewMore"
      >
        {{ datas.Title }}
      </h2>
      <p class="home-news-item-1-content" :title="datas.Brief">
        {{ datas.Brief }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "News1",
  props: {
    datas: {
      type: Object,
      default: () => {
        return {
          Title: "",
          PublishTime: "",
          PicUrl: "",
          Brief: "",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    viewMore() {
      this.$emit("viewMore", this.datas);
    },
  },
};
</script>

<style lang="scss">
.home-news-item-1 {
  padding: 30px;
  width: 320px;
  height: 240px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.08);
  &:hover {
    .home-news-item-1-top {
      .home-news-item-1-date {
        p {
          color: #b60005;
        }
        .home-news-item-1-date-day {
          color: #b60005;
        }
      }
    }
    .view-more {
      margin-right: -5px;
    }
  }
  .home-news-item-1-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 15px;
    margin-bottom: 17px;
    .view-more {
      // margin-right: 30px;
      transition: 0.4s;
      &:hover {
        margin-right: -5px;
      }
    }
    .home-news-item-1-date {
      p {
        font-size: 38px;
        color: #181818;
        line-height: 50px;
        &.home-news-item-1-date-day {
          font-size: 14px;
          color: #181818;
          line-height: 19px;
        }
      }
    }
  }
  .home-news-item-1-bottom {
    h2 {
      font-size: 18px;
      color: #181818;
      line-height: 28px;
      height: 28px;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        color: #b60005;
      }
    }
    p {
      font-size: 14px;
      color: #6e6d72;
      line-height: 23px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
.home-news-list .home-news-item-1:last-child {
  margin-bottom: 0;
}
</style>
