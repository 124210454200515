<template>
  <div class="zjly-web-news-detail">
    <div class="zjly-web-news-detail-breadcrumb w1200">
      <breadcrumb :datas="linksList"></breadcrumb>
    </div>
    <div class="zjly-web-news-detail-nr w1200">
      <h2 class="zjly-web-news-detail-h2">{{ articleDetail.Title }}</h2>
      <div class="zjly-web-news-detail-time">
        {{ articleDetail.PublishTime }}
      </div>
      <div
        class="zjly-web-news-detail-html"
        v-html="articleDetail.Content"
      ></div>
    </div>
    <div class="w1200">
      <ul class="news-other">
        <li class="zjly-ellipsis">
          <a @click="goToLinks(-1)" v-if="prevNextNews.FrontId"
            >上一篇：{{ prevNextNews.FrontName }}</a
          >
        </li>
        <li class="zjly-ellipsis">
          <a @click="goToLinks(1)" v-if="prevNextNews.BackId"
            >下一篇：{{ prevNextNews.BackName }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import proMixins from "../../mixins/proMixins";
import Breadcrumb from "../../component/pagenavbar/breadcrumb.vue";
import api from "../../api/api";
export default {
  name: "NewsDetail",
  components: {
    Breadcrumb,
  },
  mixins: [proMixins],
  data() {
    return {
      linksList: [],
      newsLink: [],
      prevNextNews: {},
    };
  },
  watch: {
    articleDetail: {
      deep: true,
      handler(val) {
        if (val && val.Title) {
          // 设置面包屑导航数组
          let links = [];
          let about = Object.assign({ type: 2 }, this.currentMenu);
          links.push(about);
          let lastNav = {
            MenuId: val.Id,
            Label: val.Title,
            type: 0,
          };
          links.push(lastNav);
          this.newsLink = links;
        }
      },
    },
    allMenu() {
      if (this.articleDetail) {
        this.getBreadCrumbLsit(this.articleDetail);
      }
    },
  },
  mounted() {},
  methods: {
    afterGetCurrentMenu() {
      this.getArticleDetail();
      this.getPrevNextNews(this.$route.params.id);
    },
    getPrevNextNews(id) {
      let params = {
        QueryParam: {
          Id: id,
        },
      };
      api.getFrontAndBack(params).then((res) => {
        let data = res.data.Results || {};
        this.prevNextNews = data;
      });
    },
    goToLinks(num) {
      let id = "";
      if (num < 0) {
        // 上一条
        id = this.prevNextNews.FrontId;
      } else {
        // 下一条
        id = this.prevNextNews.BackId;
      }
      if (!id) {
        console.log("没有上下篇id");
      }
      this.getArticleDetail(id);
      this.getPrevNextNews(id);
    },
  },
};
</script>

<style lang="scss"></style>
