<template>
  <div class="home-product-list zjly-swiper">
    <swiper
      class="home-product-list-thumbs"
      :options="swiperOptionThumbs"
      ref="swiperThumbs"
      @ready="handleSwiperReadied"
      :auto-update="true"
      :auto-destroy="true"
      :delete-instance-on-destroy="true"
      :cleanup-styles-on-destroy="true"
    >
      <swiper-slide
        class="slide-1"
        v-for="(item, index) in proList"
        :key="index"
        :class="index === swiperIndex ? 'highlight-swiper' : ''"
      >
        <card-type-2
          :datas="item"
          @viewMore="viewMore"
          :isLazy="index == proList.length - 1 ? false : true"
        ></card-type-2>
      </swiper-slide>
      <div
        class="swiper-pagination"
        slot="pagination"
        v-show="proList.length > 3"
      ></div>
    </swiper>
    <div class="swiper-button-prev" v-show="proList.length > 3">
      <i class="slider-btn-prev"></i>
    </div>
    <div class="swiper-button-next" v-show="proList.length > 3">
      <i class="slider-btn-next"></i>
    </div>
  </div>
</template>

<script>
import cardType2 from "../../component/card/card-type-2.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
// import defaults from "../../defaults/defaults";
export default {
  components: { cardType2, Swiper, SwiperSlide },
  name: "CaseList",
  inject: ["getAllMenu"],
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOptionThumbs: {
        loop: false,
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 43,
        slideClass: "custom-slide-class",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // effect: "fade",
      },
      currentSceneIndexDefault: 0,
      swiperIndex: this.currentSceneIndexDefault,
      proList: [
        {
          Title: "浙江省刑侦涉案视频实战应用系统",
          PicUrl: "",
          Content: "系统遵从公安部“一机一档”系统建设思路，以及省、市的用",
        },
        {
          Title: "视频联网共享2",
          PicUrl: "",
          Content:
            "系统遵从公安部“一机一档”系统建设思路，以及省、市的“一机一档”建设要求",
        },
        {
          Title: "视频联网共享3",
          PicUrl: "",
          Content:
            "系统遵从公安部“一机一档”系统建设思路，以及省、市的“一机一档”建设要求，立足公安实战需求，以设备档案全生命周期管理应用",
        },
        {
          Title: "视频联网共享4",
          PicUrl: "",
          Content:
            "系统遵从公安部“一机一档”系统建设思路，以及省、市的“一机一档”建设要求，立足公安实战需求，以设备档案全生命周期管理应用",
        },
      ],
    };
  },
  computed: {
    allMenu() {
      return this.getAllMenu();
    },
  },
  watch: {
    datas: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.proList = val.slice();
        }
      },
    },
  },
  methods: {
    viewMore(item) {
      // 跳转
      this.$router.push({
        path: "/casedetail/" + item.ArticleId,
      });
    },
    prevPage() {},
    nextPage() {},
    handleSwiperReadied() {
      this.$refs.swiperThumbs.$swiper.update();
    },
  },
};
</script>

<style lang="scss">
.home-product-list {
  width: 100%;
  // padding: 32px;
  .swiper-container {
    padding-top: 20px;
    height: 600px;
    padding: 32px;
  }
  .home-product-list-thumbs {
    .custom-slide-class {
      width: 372px;
      height: 500px;
    }
  }
}
</style>
