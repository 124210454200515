<template>
  <!-- 定位时，滚动条必须在body的子元素上才能生效且必须给id为scrollbarWarp -->
  <!-- <div class="locate-page">
    <div
      class="time-plan-row"
      v-for="(item, index) in datas"
      :key="item.Id"
      @click="clickStep(item.Id, index)"
      :class="{ active: item.Id === comValue }"
    >
      <div class="time-plan-row-item-text">{{ item.Cnt }}</div>
    </div>
  </div> -->
  <div class="page-side-nav-bar">
    <ul>
      <li
        v-for="(item, index) in datas"
        :key="item.Id"
        :class="{ active: item.Id === comValue }"
        @click="clickStep(item.Id, index)"
      >
        <span class="zjly-ellipsis"> {{ item.Cnt }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LocatePage",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    datas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      comValue: "", // 当前选中值
      scrollHeight: [], // 锚点高度
      isLocateType: false, // 是否锚点定位
      timer: null,
    };
  },
  watch: {
    value(val) {
      this.comValue = val;
    },
  },
  mounted() {
    this.comValue = this.value;
    this.$nextTick(() => {
      this.getScrollHeight();
    });
    document.body.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    clickStep(Id, index) {
      this.comValue = Id;
      this.$emit("change", Id);
      const anchorPoint = document.getElementById(Id);
      if (anchorPoint) {
        const offsetTop = anchorPoint.offsetTop;
        let needMoveTop = index ? offsetTop - 10 : 0;
        document.body.scrollTo({
          top: needMoveTop - 110,
          behavior: "smooth",
        });
        this.isLocateType = true;
        if (this.timer) {
          clearTimeout(this.timer);
        }
      }
    },

    getScrollHeight() {
      this.scrollHeight = [];
      this.datas.map((item) => {
        this.scrollHeight.push({
          ...item,
          height: document.getElementById(item.Id).offsetTop - 20,
        });
      });
    },
    handleScroll(e) {
      if (this.timer) {
        return;
      }
      if (this.isLocateType) {
        this.timer = setTimeout(() => {
          this.isLocateType = false;
          this.timer = null;
        }, 1000);
        return;
      }
      if (
        e.target.offsetHeight + e.target.scrollTop ===
        e.target.scrollHeight
      ) {
        this.comValue = this.datas[this.datas.length - 1].Id;
        this.$emit("change", this.comValue);
        return;
      }
      for (let i = 0; i < this.datas.length; i++) {
        let currentDom = document.getElementById(this.datas[i].Id);
        let positionInfo = currentDom.getBoundingClientRect();
        //  会出现零点几的误差，所以要大于一
        if (positionInfo.bottom > 1) {
          this.comValue = this.datas[i].Id;
          this.$emit("change", this.comValue);
          return;
        }
      }
    },
  },
  beforeDestroy() {
    document.body.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
$circleRadius: 8px;
$rowHeight: 38px;
.locate-page {
  // position: fixed;
  // right: 40px;
  // top: 54px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 110px;
  .time-plan-row {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    font-family: MicrosoftYaHei;
    text-align: center;
    font-style: normal;
    color: #7f7f7f;
    cursor: pointer;
    height: $rowHeight;
    &:not(:first-child)::before,
    &:not(:last-child)::after {
      content: "";
      width: 1px;
      height: 50%;
      background: #cfd3d9;
      position: absolute;
      left: calc($circleRadius / 2);
      transform: translateX(-50%);
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    .time-plan-row-circle {
      width: calc($circleRadius + 3px);
      height: calc($circleRadius + 3px);
      background: #c0c4cc;
      box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.25);
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
      z-index: 1;
      transform: translate(-1.5px, 0);
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: calc($circleRadius - 1px);
        height: calc($circleRadius - 1px);
        border-radius: 50%;
        z-index: 2;
        background: #eceef1;
        transform: translate(2px, 2px);
      }
    }
    .time-plan-row-item-circle {
      width: $circleRadius;
      height: $circleRadius;
      background: #c0c4cc;
      box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.25);
      border-radius: 50%;
      position: relative;
      margin-right: 20px;
      z-index: 1;
    }
    .time-plan-row-item-text {
      font-size: 14px;
      line-height: 16px;
    }
    .time-plan-row-item-value {
      font-size: 14px;
      line-height: 22px;
    }
    &:first-child,
    &:last-child {
      cursor: default;
      height: calc($rowHeight / 2);
    }
  }

  .active {
    .time-plan-row-item-circle {
      position: relative;
    }
    .time-plan-row-item-text {
      color: #0f74dc;
    }
    .time-plan-row-item-circle::after {
      content: "";
      display: block;
      width: 20px;
      height: 12px;
      background: #0f74dc;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(calc(-50% + 2px), -50%);
      border-radius: 2px;
      clip-path: polygon(0 0, 60% 0, 100% 50%, 60% 100%, 0 100%);
    }
  }
}
</style>
