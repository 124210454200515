import Vue from "vue";
import App from "./App.vue";
// import * as VueAos from "vue-aos";
// import Navigation from "vue-navigation";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "animate.css";
// import VueAnimateOnScroll from "vue-animate-onscroll";

import uiframeComponent from "uiframe-component";
import "uiframe-component/lib/uiframe-component.css";

import router from "./router/router";
// Vue.use(Navigation, { router });
// Vue.use(VueAos);
Vue.config.productionTip = false;
Vue.use(uiframeComponent);
Vue.use(ElementUI);
// Vue.use(VueAnimateOnScroll);

window.__vm = new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");

// 每个路由页面的title不同,个人中心页面需要登录才能进入
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title;
  window.scrollTo(0, 0);
  if (to.matched.length === 0) {
    next("/error");
  } else {
    next();
  }
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (localStorage.getItem("userId")) {
      next();
    } else {
      // next({
      //   path: '/user_login',
      //   query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
      // })
    }
  } else {
    next();
  }
});
