<template>
  <div class="card-type-4">
    <el-image class="card-type-4-image" :src="datas.PicUrl" @click="goTodetail">
      <div slot="error" class="image-error-slot"></div>
      <!-- <div slot="placeholder" class="lyt-image-load-slot">加载中...</div> -->
    </el-image>
    <div class="card-type-4-main-content">
      <h3>
        <span class="zjly-ellipsis" @click="goTodetail">{{ datas.Title }}</span>
      </h3>
      <div class="card-type-content">{{ datas.Brief }}</div>
      <div class="card-type-bottom">
        <a @click="goTodetail">了解更多<i></i></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardType4",
  props: {
    datas: {
      type: Object,
      default: () => {
        return {
          Title: "视频联网共享",
          PicUrl: "",
          Content:
            "系统遵从公安部“一机一档”系统建设思路，以及省、市的“一机一档”建设要求，立足公安实战需求，以设备档案全生命周期管理应用",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    goTodetail() {
      this.$emit("goToDetail", this.datas);
    },
  },
};
</script>

<style lang="scss">
.card-type-4 {
  display: flex;
  height: 352px;
  box-sizing: border-box;
  background: #ffffff;
  box-sizing: border-box;

  .card-type-4-main-content {
    width: calc(100% - 610px);
    box-sizing: border-box;
    padding-left: 50px;
  }
  .card-type-4-image {
    width: 610px;
    height: 352px;
    flex: 0 auto;
    border-radius: 10px;
    cursor: pointer;
    img {
      transition: all 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }
    .image-error-slot {
      width: 100%;
      height: 100%;
      background: #efefef;
    }
  }
  h3 {
    font-size: 22px;
    color: #181818;

    // height: 29px;
    text-align: left;
    padding-top: 57px;
    cursor: pointer;
    &:hover {
      color: #b60005;
    }
    span {
      border-bottom: 2px solid #b60005;
      padding-bottom: 15px;
      line-height: 29px;
      display: inline-block;
      max-width: 100%;
    }
  }
  .card-type-content {
    height: 148px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    padding: 22px 0;
    box-sizing: border-box;
    margin-bottom: 22px;
    line-height: 30px;
    color: #6e6d72;
  }
  .card-type-bottom {
    flex: 0 auto;
    height: 50px;
    text-align: right;
  }
}
</style>
