<template>
  <div class="home-product-list zjly-swiper">
    <swiper
      class="home-product-list-thumbs"
      :options="swiperOptionThumbs"
      ref="swiperThumbs"
      @ready="handleSwiperReadied"
      :auto-update="true"
      :auto-destroy="true"
      :delete-instance-on-destroy="true"
      :cleanup-styles-on-destroy="true"
    >
      <swiper-slide
        class="slide-1"
        v-for="(item, index) in proList"
        :key="index + item.Id"
        :class="index === swiperIndex ? 'highlight-swiper' : ''"
      >
        <card-type-1
          :datas="item"
          @viewMore="viewMore(item)"
          :key="index + item.Id"
          :isLazy="index == proList.length - 1 ? false : true"
        ></card-type-1>
      </swiper-slide>
      <div
        class="swiper-pagination"
        slot="pagination"
        v-show="proList.length > 3"
      ></div>
    </swiper>
    <div class="swiper-button-prev" v-show="proList.length > 3">
      <i class="slider-btn-prev"></i>
    </div>
    <div class="swiper-button-next" v-show="proList.length > 3">
      <i class="slider-btn-next"></i>
    </div>
  </div>
</template>

<script>
import cardType1 from "../../component/card/card-type-1.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: { cardType1, Swiper, SwiperSlide },
  name: "ProductList",
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOptionThumbs: {
        loop: false,
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 43,
        slideClass: "custom-slide-class",
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // effect: "fade",
      },
      currentSceneIndexDefault: 0,
      swiperIndex: this.currentSceneIndexDefault,
      proList: [],
    };
  },
  watch: {
    datas: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.proList = val.slice();
        }
      },
    },
  },
  methods: {
    viewMore(item) {
      this.$router.push({
        name: "product",
        params: { productType: { second: item.MenuId } },
      });
    },
    prevPage() {},
    nextPage() {},
    handleSwiperReadied() {
      this.$refs.swiperThumbs.$swiper.update();
    },
    slideChangeTransitionEnd: function () {
      // 当切换结束时，初始化懒加载的el-image组件
      const activeIndex = this.activeIndex;
      const images = document.querySelectorAll(".el-image"); // 根据实际选择器调整
      images.forEach((image, index) => {
        if (index === activeIndex) {
          // 假设el-image有某种方法来触发懒加载，这里调用该方法
          image.elImageLazyLoadMethod();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.home-product-list {
  width: 100%;
  // padding: 32px;
  .swiper-container {
    // padding-top: 20px;
    height: 600px;
    padding: 22px;
  }
  .home-product-list-thumbs {
    .custom-slide-class {
      width: 372px;
      height: 500px;
    }
  }
}
</style>
