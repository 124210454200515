<template>
  <div class="card-type-3">
    <i v-if="datas.IsNew == 1" class="card-type-new-bit">HOT</i>
    <div class="card-type-3-main">
      <el-image
        class="card-type-3-image"
        :src="datas.PicUrl"
        lazy
        @click="goTodetail"
      >
        <div slot="error" class="image-error-slot"></div>
        <!-- <div slot="placeholder" class="lyt-image-load-slot">加载中...</div> -->
      </el-image>
      <div class="card-type-3-main-content">
        <h3 class="zjly-ellipsis" @click="goTodetail">{{ datas.Title }}</h3>
        <div class="card-type-content">{{ datas.Brief }}</div>
        <div class="card-type-bottom">
          <a @click="goTodetail">了解更多<i></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardType3",
  props: {
    datas: {
      type: Object,
      default: () => {
        return {
          Title: "",
          PicUrl: "",
          Content: "",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    goTodetail() {
      this.$emit("goToDetail", this.datas);
    },
  },
};
</script>

<style lang="scss">
.card-type-3 {
  width: 384px;
  height: 486px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  background: #ffffff;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  .card-type-new-bit {
    color: #ffffff;
    font-size: 12px;
    background: url(../../assets/img/img_hot.png) no-repeat;
    border-radius: 0 0 0 12px;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 32px;
    // padding: 0 5px 0 10px;
    height: 38px;
    line-height: 53px;
    text-align: center;
    z-index: 10;
  }
  &:hover {
    box-shadow: 0px 0px 32px 0px rgba(24, 24, 24, 0.08);
    img {
      transform: scale(1.05);
    }
  }
  .card-type-3-main {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .card-type-3-main-content {
    padding: 0 36px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .card-type-3-image {
    width: 100%;
    height: 233px;
    flex: 0 auto;
    margin-bottom: 42px;
    cursor: pointer;
    img {
      transition: all 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }
    .image-error-slot {
      width: 100%;
      height: 100%;
      background: #efefef;
    }
  }
  h3 {
    font-size: 22px;
    color: #181818;
    line-height: 29px;
    // height: 29px;
    text-align: left;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
      color: #b60005;
    }
  }
  .card-type-content {
    height: 82px;
    // flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    min-height: 35px;
    margin-bottom: 28px;
    color: #6e6d72;
  }
  .card-type-bottom {
    flex: 0 auto;
    height: 50px;
  }
}
</style>
