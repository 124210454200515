<template>
  <div class="zjly-web-news">
    <div class="w1200">
      <div class="zjly-web-news-top">
        <template v-for="(item, index) in newsList">
          <div
            class="zjly-web-news-top-item"
            v-if="item.IsTop == 1"
            :key="index"
            @click="goToNewsDetail(item)"
          >
            <el-image :src="item.PicUrl">
              <div slot="error" class="image-error-slot"></div>
            </el-image>
            <h3>{{ item.Title }}</h3>
            <div class="zjly-web-news-top-item-icon"><i class="">置顶</i></div>
          </div>
        </template>
      </div>
      <template v-for="(item, index) in newsList">
        <div class="zjly-web-news-item" :key="index" v-if="item.IsTop != 1">
          <div class="zjly-web-news-item-date">
            <p class="day">
              {{ item.PublishTime ? item.PublishTime.substring(8, 10) : "" }}
            </p>
            <p class="datetime">
              {{ item.PublishTime ? item.PublishTime.substring(0, 4) : "" }}.{{
                item.PublishTime ? item.PublishTime.substring(5, 7) : ""
              }}
            </p>
          </div>
          <div class="zjly-web-news-item-nr">
            <h3 class="zjly-ellipsis" @click="goToNewsDetail(item)">
              {{ item.Title }}
            </h3>
            <div class="zjly-web-news-item-brief">
              {{ item.Brief }}
            </div>
          </div>
        </div>
      </template>
      <div class="page-box">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :currentPage="currentPage"
          :pageSize="pageSize"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import defaults from "../../defaults/defaults";
export default {
  name: "News",
  data() {
    return {
      newsList: [],
      total: 0,
      pageSize: defaults.datagrid.pageSize,
      currentPage: 1,
    };
  },
  mounted() {
    this.getNewsList();
  },
  methods: {
    //上一页，下一页
    changePage(page) {
      this.currentPage = page;
      this.getNewsList();
    },
    /**
     * 新闻详情-跳转
     * @param {*} item
     */
    goToNewsDetail(item) {
      this.$router.push({
        path: "/newsdetail/" + item.Id,
        // params: { id: item.Id },
      });
    },
    /**
     * 新闻列表
     */
    getNewsList() {
      let params = {
        QueryParam: {
          MenuId: defaults.menuIds.news, //菜单Id
          Status: "1", //发布状态 1已发布 0未发布
        },
        RecordStartNo: (this.currentPage - 1) * this.pageSize,
        PageRecordNum: this.pageSize,
      };
      api.getArticleList(params).then((res) => {
        this.newsList = res.data.Results || [];
        this.total = res.data.TotalNum || 0;
      });
    },
  },
};
</script>

<style lang="scss">
.zjly-web-news {
  padding-bottom: 80px;
  .zjly-web-news-top {
    display: flex;
    .zjly-web-news-top-item {
      width: 580px;
      margin-bottom: 60px;
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
      .el-image {
        width: 100%;
        height: 317px;
        margin-bottom: 20px;
        cursor: pointer;
        img {
          transition: all 0.3s;
          &:hover {
            transform: scale(1.05);
          }
        }
        .image-error-slot {
          width: 100%;
          height: 100%;
          background: #efefef;
        }
      }
      h3 {
        font-size: 20px;
        color: #181818;
        line-height: 26px;
        margin-bottom: 12px;
        cursor: pointer;
        &:hover {
          color: #b60005;
        }
      }
      .zjly-web-news-top-item-icon {
        i {
          font-size: 14px;
          color: #b60005;
          line-height: 22px;
          width: 48px;
          height: 24px;
          border-radius: 3px;
          border: 1px solid #b60005;
          box-sizing: border-box;
          display: block;
          text-align: center;
        }
      }
    }
  }
  .zjly-web-news-item {
    display: flex;

    margin: 50px 0;
    .zjly-web-news-item-date {
      width: 160px;
      height: 160px;
      flex: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f9f9f9;
      border-radius: 9px;
      .day {
        font-family: MicrosoftYaHei;
        font-size: 64px;
        color: #b60005;
        line-height: 85px;
        font-style: normal;
      }
      .datetime {
        font-size: 18px;
        color: #181818;
        line-height: 32px;
      }
    }
    .zjly-web-news-item-nr {
      flex: 1;
      margin-left: 40px;
      h3 {
        font-size: 22px;
        color: #181818;
        line-height: 38px;
        margin-bottom: 18px;
        display: block;
        padding-top: 20px;
        cursor: pointer;
        &:hover {
          color: #b60005;
        }
      }
      .zjly-web-news-item-brief {
        font-size: 16px;
        color: #6e6d72;
        line-height: 32px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
